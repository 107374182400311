import { IconDiscount2 } from '@tabler/icons-react'
import Card from '~/components/Card/Card'
import { ProductSpecialOfferCardProps } from '~/components/ProductSpecialOfferCard/ProductSpecialOfferCard.types'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'

export const ProductSpecialOfferCard = ({
  title,
  description,
  variant,
  url,
}: ProductSpecialOfferCardProps) => {
  return (
    <Card
      title={title}
      variant={variant}
      action={{ href: url, title }}
      icon={<IconDiscount2 size={24} />}>
      <div className='pt-0.5'>
        <Typography
          tag={TypographyTag.p}
          variant={TypographyVariant.BodyRegularExtraLineHeight}>
          {description}
        </Typography>
      </div>
    </Card>
  )
}
