import { useEffect } from 'react'
import { useQuery, QueryFunction } from '@tanstack/react-query'
import { QuickSearchProductResponse } from 'shared-types'
import { useAlgoliaSearchInsignts } from '~/hooks'
import { Client } from '~/customClients/client'
import { useSession } from '../useSession'

export function useSearchResults(query: string, enabled: boolean) {
  const { session } = useSession()
  const { updateLatestQueryId } = useAlgoliaSearchInsignts()

  const fetcher: QueryFunction<QuickSearchProductResponse, string[]> = async (
    payload
  ) => {
    try {
      const queryValue = payload?.queryKey?.[1]

      const searchResults = await Client.searchResults?.getSearchResults(
        queryValue,
        session
      )
      return searchResults?.getValue()
    } catch (err) {
      console.error(
        'Error when fetching search data from BFF',
        JSON.stringify(err)
      )
    }
  }

  const {
    data: searchResults,
    refetch: fetchSearchResults,
    isError: isErrorSearchResults,
    isLoading: isLoadingSearchResults,
  } = useQuery<QuickSearchProductResponse>(
    ['search', query, session, enabled],
    fetcher,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!session && enabled,
    }
  )

  useEffect(() => {
    updateLatestQueryId(searchResults?.queryId)
  }, [searchResults, updateLatestQueryId])

  return {
    searchResults,
    fetchSearchResults,
    isErrorSearchResults,
    isLoadingSearchResults,
  }
}
