import { useCallback, useState } from 'react'
import { useMedia } from 'react-use'
import { IconPlus } from '@tabler/icons-react'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { ProductToolTip } from 'shared-types'
import { useCustomer, useWishlist } from '~/hooks'
import { GTM, GTMEvent } from '~/lib'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import Input from '../Input'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './SaveProductTooltip.module.css'
import { SaveProductTooltipProps } from './SaveProductTooltip.types'

const defaultXOffset = 0
const defaultYOffset = 18
const offset = [defaultXOffset, defaultYOffset]

const AccountQuickViewDrawer = dynamic(
  async () => {
    const mod = await import('../AccountQuickViewDrawer')
    return mod.AccountQuickViewDrawer
  },
  { ssr: false }
)

const Drawer = dynamic(
  async () => {
    const mod = await import('../Drawer')
    return mod.Drawer
  },
  { ssr: false }
)

const Tooltip = dynamic(
  async () => {
    const mod = await import('@overdose/components')
    return mod.Tooltip
  },
  { ssr: false }
)

export const SaveProductTooltip = ({
  toggle,
  buttonProps,
  products,
  wrapperClass,
  clearAllProducts,
}: SaveProductTooltipProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [open, setOpen] = useState(false)
  const isMobileView = useMedia('(max-width: 767px)', false)
  const productsToSave = products?.map((product) => {
    return { sku: product.sku, quantity: 1 }
  })

  const { lists, addToList, createList, removeToList } = useWishlist(
    productsToSave,
    open
  )

  const [openAccountQuickViewer, setOpenAccountQuickViewer] = useState(false)
  const toggleOpen = () => {
    setOpen((prev) => {
      return !prev
    })
  }

  const [openTooltip, setOpenTooltip] = useState(false)
  const handleOpenTooltip = useCallback(() => {
    setOpenTooltip(true)
  }, [])
  const handleCloseTooltip = useCallback(() => {
    setOpenTooltip(false)
  }, [])

  const { customer } = useCustomer()
  const router = useRouter()

  const handleAddUpdateItem = useCallback(
    async (item: ProductToolTip) => {
      if (item.id) {
        if (!item.containsAllProducts) {
          GTM.dispatch(GTMEvent.ADD_TO_LIST, { product: products })
          return addToList(item.id)
        }
        return removeToList(item.id)
      }
      return createList(item.title)
    },
    [products, addToList, createList, removeToList]
  )

  const handleAccountViewer = useCallback(() => {
    if (customer) {
      setOpenAccountQuickViewer(true)
    } else {
      router.push({ ...router, hash: 'login' })
    }
  }, [customer, router])

  const renderLabel = useCallback((text: string) => {
    return (
      <Typography tag={TypographyTag.p} variant={TypographyVariant.BodyRegular}>
        {text}
      </Typography>
    )
  }, [])

  const renderSearchInput = useCallback(() => {
    return (
      <Input
        name='SearchOrCreateList'
        placeholder='Search or create list'
        value={searchTerm}
        onChange={(value) => {
          setSearchTerm(value)
        }}
      />
    )
  }, [searchTerm])

  const renderNewItem = useCallback(() => {
    return (
      searchTerm && (
        <button
          type='button'
          data-id='AddToWishlistButton'
          className='py-2.5 pl-2 pr-6 overflow-x-hidden bg-greyscale-100 rounded-lg flex w-full justify-between relative shrink-0'
          onClick={(e) => {
            handleAddUpdateItem({
              title: searchTerm,
              containsAllProducts: false,
              id: '',
            })
            setSearchTerm('')
            e.stopPropagation()
          }}>
          {renderLabel(searchTerm)}
          <IconPlus
            size={16}
            stroke='var(--color-typography-heading)'
            className='absolute right-2 z-10'
          />
        </button>
      )
    )
  }, [renderLabel, searchTerm, handleAddUpdateItem])

  const renderList = useCallback(() => {
    return lists
      ?.filter(({ title }) => {
        return title
          ?.toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase())
      })
      .map(({ containsAllProducts, title, id }, index) => {
        return (
          <div
            className={classNames(
              'py-2.5 px-2 rounded-lg hover:bg-greyscale-100 group',
              { 'bg-greyscale-100': containsAllProducts }
            )}
            key={index}>
            <Checkbox
              data-id='AddToWishlistButton'
              label={renderLabel(title)}
              name={`${title}_${index}`}
              theme={{
                root: 'w-full',
                content: 'order-1 flex-1 max-w-none',
                box: 'order-2',
              }}
              size='md'
              defaultChecked={containsAllProducts}
              checked={containsAllProducts}
              onChange={async () => {
                if (clearAllProducts) {
                  clearAllProducts()
                  handleCloseTooltip()
                }
                await handleAddUpdateItem({
                  title,
                  id,
                  containsAllProducts,
                })
              }}
            />
          </div>
        )
      })
  }, [
    clearAllProducts,
    handleAddUpdateItem,
    handleCloseTooltip,
    lists,
    renderLabel,
    searchTerm,
  ])

  return (
    <div className={classNames('flex items-center', wrapperClass || '')}>
      {customer ? (
        <Tooltip
          theme={{
            root: classNames(styles.tooltip, 'p-0 pb-2 hidden md:block'),
          }}
          trigger='click'
          offset={offset}
          position='top-start'
          popupVisible={openTooltip}
          triggerProps={{
            onOpen: () => {
              handleOpenTooltip()
              return setOpen(true)
            },
            onClose: () => {
              handleCloseTooltip()
              setOpenAccountQuickViewer(false)
              return setOpen(false)
            },
          }}
          content={
            <div className='flex flex-col'>
              <div className='grow overflow-x-hidden px-2 py-4 flex flex-col gap-2 h-40'>
                <div className='overflow-y-auto grow'>{renderList()}</div>
                {renderNewItem()}
              </div>
              <div className='border-t border-primary-border'>
                {renderSearchInput()}
              </div>
            </div>
          }>
          <div>
            <Button
              {...buttonProps}
              className='hidden md:flex'
              onClick={toggleOpen}>
              {toggle}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          {...buttonProps}
          onClick={handleAccountViewer}
          className='hidden md:flex'>
          {toggle}
        </Button>
      )}

      <Button
        {...buttonProps}
        className='flex p-0 border-0 outline-0 hidden xs:block xs:flex md:hidden'
        onClick={() => {
          !customer ? handleAccountViewer() : toggleOpen()
        }}>
        {toggle}
      </Button>

      {isMobileView && (
        <Drawer
          wrapperClass={classNames(styles.drawer, 'block md:hidden')}
          theme={{
            header: styles.drawerHeader,
            close: styles.close,
            title: classNames(styles.title, 'w-full h-14 flex items-center'),
          }}
          title='Lists'
          isOpen={open}
          placement='bottom'
          onClose={toggleOpen}>
          <div className='p-4 flex flex-col gap-4'>
            {renderSearchInput()}
            {renderNewItem()}
            {renderList()}
          </div>
        </Drawer>
      )}
      <AccountQuickViewDrawer
        open={openAccountQuickViewer}
        onClose={() => {
          setOpenAccountQuickViewer(false)
        }}
      />
    </div>
  )
}
