import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import CategoryTile from '../CategoryTile/CategoryTile'
import { CategoryTilesProps } from './CategoryTiles.types'

const CategoryTiles = ({ tiles }: CategoryTilesProps) => {
  return (
    <Swiper slidesPerView='auto'>
      {tiles.map((tile, index) => {
        return (
          <SwiperSlide key={index} className='!w-min mr-4 md:mr-6'>
            <CategoryTile {...tile} />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default CategoryTiles
