import { FieldPath, UseFormRegisterReturn } from 'react-hook-form'
import { Checkbox as BaseCheckbox } from '@overdose/components'
import classNames from 'classnames'

import styles from './Checkbox.module.css'
import { CheckboxProps } from './Checkbox.types'

export const Checkbox = <
  TFormValues extends Record<string, unknown> = Record<string, unknown>
>({
  name,
  label,
  checked,
  disabled,
  defaultChecked,
  onChange,
  size,
  theme = {},
  style,
  register,
}: CheckboxProps<TFormValues>) => {
  const useFormProps = register
    ? register(name as FieldPath<TFormValues>)
    : ({} as UseFormRegisterReturn)

  return (
    <BaseCheckbox
      style={{
        '--checkbox-bg-color-checked':
          checked && disabled
            ? 'var(--color-background-checkbox-checked-disable)'
            : 'var(--color-state-success-light)',
        '--checkbox-content-margin-boxed': 'var(--checkbox-content-margin)',
        '--checkbox-content-padding-boxed': '0',
        color: 'var(--color-checkbox-stroke-line)',
        ...style,
      }}
      name={name}
      label={label}
      theme={{
        ...theme,
        box: classNames(
          checked && !disabled ? styles.checkboxActive : styles.box,
          theme.box
        ),
        checked_icon: styles.checkIcon,
        label: classNames(
          disabled ? styles.inactiveLabelColor : styles.activeLabelColor,
          theme.label
        ),
        checked: styles.checked,
        input: styles.inputField,
      }}
      size={size}
      defaultChecked={defaultChecked}
      checked={checked}
      disabled={disabled}
      {...useFormProps}
      onChange={(
        state: boolean,
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        if (useFormProps?.name) {
          useFormProps?.onChange(event)
        }
        return onChange?.(state)
      }}
    />
  )
}
