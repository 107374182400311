import { useCallback, useMemo, useState, useEffect } from 'react'
import {
  IconInfoCircle,
  IconTruck,
  IconCurrentLocation,
} from '@tabler/icons-react'
import dynamic from 'next/dynamic'
import { CustomLocation } from 'shared-types'
import { Button } from '~/components/Button'
import Card from '~/components/Card/Card'
import { LocationSearch } from '~/components/LocationSearch'
import Status from '~/components/Status/Status'
import { ProductDeliverToPostcodeStatusCardProps } from '~/components/ProductDeliverToPostcodeStatusCard/ProductDeliverToPostcodeStatusCard.types'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'

import { useSession, useToast, useUserDeliveryPostcode } from '~/hooks'
import { Client } from '~/customClients/client'
import { DeliveryPostcodeProps } from '../PostcodeConfigureDrawer/PostcodeConfigureDrawer.types'

const ProductDeliverToPostcodeStatusTooptip = dynamic(
  async () => {
    const mod = await import('./ProductDeliverToPostcodeStatusTooptip')
    return mod.ProductDeliverToPostcodeStatusTooptip
  },
  {
    loading: () => {
      return (
        <span className='z-[11] relative inline-block cursor-pointer'>
          <IconInfoCircle
            size={20}
            color='var(--product-status-tooltip-color)'
            className='inline'
          />
        </span>
      )
    },
    ssr: false,
  }
)

export const ProductDeliverToPostcodeStatusCard = ({
  postcode,
  sku,
  freightServiceMessage,
  freightServiceErrorCode,
  productPrice,
}: ProductDeliverToPostcodeStatusCardProps) => {
  const {
    promptUserPostcodeDrawer,
    findDeliveryOptionsNoPrompt,
    deliveryMethods,
    postcode: providerPostcode,
    freightServiceMessage: providerFreightServiceMessage,
    freightServiceErrorCode: providerFreightErrorCode,
    city: providerCity,
    sku: providerSku,
  } = useUserDeliveryPostcode()

  const { session } = useSession()
  const { showNotification, closeNotification } = useToast()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    findDeliveryOptionsNoPrompt('', 0, true)
  }, [findDeliveryOptionsNoPrompt])

  const freightServiceResponse = useMemo(() => {
    return freightServiceMessage || providerFreightServiceMessage
  }, [freightServiceMessage, providerFreightServiceMessage])

  const freightErrorResponse = useMemo(() => {
    return freightServiceErrorCode || providerFreightErrorCode
  }, [freightServiceErrorCode, providerFreightErrorCode])

  const handleChangePostcode = (value: DeliveryPostcodeProps) => {
    findDeliveryOptionsNoPrompt(sku, productPrice)

    session.token.deliveryPostcode = value.postcode
    session.token.deliveryCity = value.city
  }

  const getDeliveryMethods = useCallback(async () => {
    setLoading(true)

    const response = await Client.store.getDeliverToPostcodeAvailabilityStatus(
      session,
      providerPostcode,
      sku,
      productPrice
    )
    const deliveryMethodsError = response.getErrorSafe()
    if (deliveryMethodsError) {
      showNotification({
        children: deliveryMethodsError.message,
        autoClose: true,
        id: 'deliveryMethods-error',
        type: 'error',
        onClose: closeNotification,
      })
    }

    setLoading(false)
    return response.getValueSafe()?.deliveryMethods
  }, [
    closeNotification,
    providerPostcode,
    session,
    showNotification,
    sku,
    productPrice,
  ])

  return (
    <Card
      title={
        <button
          aria-label='Add postcode for delivery availability'
          type='button'
          className='bg-transparent'
          onClick={() => {
            return promptUserPostcodeDrawer(sku, productPrice)
          }}>
          Deliver to{' '}
          <span className='underline'>
            {providerCity}, {providerPostcode}
          </span>
        </button>
      }
      variant='default'
      icon={<IconTruck size={24} />}>
      <div className='mt-2'>
        <LocationSearch
          placeholder='Enter postcode or suburb for delivery options'
          name='search'
          type='postcode'
          value={providerPostcode}
          sku={providerSku}
          onLocationSelected={(location?: CustomLocation) => {
            handleChangePostcode({
              postcode: location.postcode,
              city: location.locality,
            })
          }}
        />
      </div>
      <div className='pl-9'>
        {freightErrorResponse === 0 &&
        deliveryMethods &&
        deliveryMethods?.length > 0 ? (
          deliveryMethods.map((method, index) => {
            return (
              <Status
                key={index}
                label={
                  <>
                    {method.deliveryLabel} - $
                    {!method.deliveryCharge ? '0' : method.deliveryCharge}
                  </>
                }
                status='success'
              />
            )
          })
        ) : (
          <Status
            label={freightServiceResponse}
            status={freightErrorResponse > 0 ? 'error' : null}
          />
        )}
      </div>
    </Card>
  )
}
