import { useState } from 'react'
import { IconBoxSeam } from '@tabler/icons-react'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import Card from '~/components/Card/Card'
import { ProductBulkPricingCardProps } from '~/components/ProductBulkPricingCard/ProductBulkPricingCard.types'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { useFormatPrice } from '~/hooks'
import { Button } from '../Button'
import styles from './ProductBulkPricingCard.module.css'

const Drawer = dynamic(
  async () => {
    const mod = await import('../Drawer')
    return mod.Drawer
  },
  { ssr: false }
)

export const ProductBulkPricingCard = ({
  tiers,
  isCompact,
}: ProductBulkPricingCardProps) => {
  const [open, setOpen] = useState(false)
  const valuesLength = tiers?.length
  const toggleDrawer = () => {
    setOpen(!open)
  }
  const { formatPrice } = useFormatPrice()
  const drawerProps = {
    theme: {
      header: classNames('p-4 md:p-6', styles.drawerHeader),
      inner: 'flex flex-col',
      footer: classNames('p-4 md:p-6', styles.drawerFooter),
    },
    title: (
      <Typography
        variant={TypographyVariant.BodyLargeBold}
        tag={TypographyTag.p}
        className='text-primary-heading flex gap-2'>
        <IconBoxSeam size={24} />
        <span>Bulk Pricing</span>
      </Typography>
    ),
    isOpen: open,
    onClose: toggleDrawer,
    children: (
      <div className='p-4 md:p-6 overflow-auto'>
        {!!valuesLength && (
          <div className={classNames('flex gap-4 flex-col')}>
            {tiers.map(({ finalPrice, minimumQuantity }, index) => {
              const isLastTier = index === tiers.length - 1
              const nextTier = tiers[index + 1]
              const maximumQuantity = nextTier
                ? nextTier.minimumQuantity - 1
                : null
              return (
                <div
                  key={minimumQuantity}
                  className={classNames(
                    'flex border rounded-md border-primary-border p-4 gap-2 relative justify-between'
                  )}>
                  <Typography
                    tag={TypographyTag.p}
                    variant={TypographyVariant.BodyRegularBold}>
                    Quantity {minimumQuantity}
                    {isLastTier ? '+' : ` - ${maximumQuantity}`}
                  </Typography>
                  <Typography
                    tag={TypographyTag.p}
                    variant={TypographyVariant.BodyRegular}>
                    {formatPrice(finalPrice)} / Unit
                  </Typography>
                </div>
              )
            })}
          </div>
        )}
      </div>
    ),
    footer: (
      <Button
        theme={{
          root: 'w-full',
        }}
        onClick={toggleDrawer}>
        Close
      </Button>
    ),
  }
  return (
    <>
      <Card
        title='Bulk Pricing'
        variant='default'
        action={isCompact && { onClick: toggleDrawer }}
        icon={<IconBoxSeam size={24} />}>
        {!isCompact && (
          <div className='md:flex items-center pl-8 pt-2 md:pt-0'>
            {!!valuesLength &&
              tiers.map((tier, index) => {
                const isLastTier = index === tiers.length - 1
                const nextTier = tiers[index + 1]
                const maximumQuantity = nextTier
                  ? nextTier.minimumQuantity - 1
                  : null
                return (
                  <div
                    key={index}
                    className={classNames(
                      styles.unitPrice,
                      'flex items-start justify-between pb-3 mb-3 border-b border-l-0',
                      'md:block md:first-of-type:pl-0 md:px-3 md:py-0 md:border-l md:first-of-type:border-l-0 md:border-b-0 md:!h-auto md:mb-0 flex-grow lg:flex-col'
                    )}>
                    <Typography
                      tag={TypographyTag.p}
                      variant={TypographyVariant.BodyRegularBold}
                      className={classNames('md:mb-1', styles.quantity)}>
                      Qty {tier.minimumQuantity}{' '}
                      {isLastTier ? '+' : `- ${maximumQuantity}`}
                    </Typography>
                    <Typography
                      tag={TypographyTag.p}
                      variant={TypographyVariant.BodyRegular}>
                      {formatPrice(tier.finalPrice)} / Unit
                    </Typography>
                  </div>
                )
              })}
          </div>
        )}
      </Card>
      <Drawer
        {...drawerProps}
        placement='bottom'
        wrapperClass='md:hidden block'
      />
      <Drawer {...drawerProps} wrapperClass='md:block hidden' />
    </>
  )
}
