import React from 'react'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'

export const NoProductsFound = () => {
  return (
    <div className='flex justify-center'>
      <Typography variant={TypographyVariant.Heading5} tag={TypographyTag.h4}>
        <span className='text-center'>No Products Found</span>
      </Typography>
    </div>
  )
}
