import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import { FilterMapProps, FilterProps } from '../../components/FiltersDrawer'
import { SelectedFilterType } from '../../components/ProductListFilters/ProductListFilters.types'

//* ******************** *//
// *** Filter Context *** //

export type FilterInfo = {
  searchType?: string
  searchQuery: SelectedFilterType
  searchFilters?: string
  selectedSortOption: string
  selectedFilterMap: FilterMapProps
}

type FilterContextType = FilterInfo & {
  filters: FilterProps[]
  setSelectedFilterMap: Dispatch<SetStateAction<FilterMapProps>>
  setSelectedSortOption: Dispatch<SetStateAction<string>>
  searchKey: string
  setSearchKey: (searchKey: string) => void
}

export const FilterContext = createContext<FilterContextType>(
  {} as FilterContextType
)

export const useFilterContext = () => {
  return useContext(FilterContext)
}

//* ********************* *//
// *** Filter Provider *** //

export type FilterProviderInfo = {
  filters: FilterProps[]
  searchType?: string
  selectedSort?: string
  selectedFilters?: SelectedFilterType[]
}

type FilterProviderProps = FilterProviderInfo & {
  children: ReactNode
}

export const FilterProvider: FC<FilterProviderProps> = ({
  filters,
  searchType,
  selectedSort,
  selectedFilters,
  children,
}) => {
  const mappedSelectedFilters = useMemo<FilterMapProps>(() => {
    return selectedFilters
      ?.filter((selectedFilter) => {
        return selectedFilter.uid !== 'slug' && selectedFilter.uid !== 'limit'
      })
      .reduce((obj, item) => {
        return {
          ...obj,
          [item.uid]: item.selectedOptions,
        }
      }, {})
  }, [selectedFilters])

  const [searchKey, setSearchKey] = useState<string>('')
  const [selectedSortOption, setSelectedSortOption] = useState<string>(
    selectedSort || ''
  )
  const [selectedFilterMap, setSelectedFilterMap] = useState<FilterMapProps>(
    mappedSelectedFilters || {}
  )

  const searchQuery: SelectedFilterType = useMemo(() => {
    return selectedFilters?.find((filterObj) => {
      return filterObj.uid === 'q'
    })
  }, [selectedFilters])

  const searchFilters = useMemo(() => {
    return selectedFilters
      ?.filter((selectedFilter) => {
        return selectedFilter.uid !== 'slug' && selectedFilter.uid !== 'q'
      })
      .map((updatedSelectedFilter) => {
        return filters
          .map((filter) => {
            return (
              filter.uid === updatedSelectedFilter.uid && {
                ...updatedSelectedFilter,
                text: filter.text,
              }
            )
          })
          .filter(Boolean)
      })
      .flat(1)
      .map((updatedSelectedFilter) => {
        return `${[
          updatedSelectedFilter.text,
        ]}: ${updatedSelectedFilter.selectedOptions.join(', ')}`
      })
      .join(' | ')
  }, [filters, selectedFilters])

  const values = useMemo(() => {
    return {
      filters,
      searchType,
      searchQuery,
      searchFilters,
      selectedFilterMap,
      setSelectedFilterMap,
      selectedSortOption,
      setSelectedSortOption,
      searchKey,
      setSearchKey,
    }
  }, [
    filters,
    searchFilters,
    searchKey,
    searchQuery,
    searchType,
    selectedFilterMap,
    selectedSortOption,
  ])

  return (
    <FilterContext.Provider value={values}>{children}</FilterContext.Provider>
  )
}
