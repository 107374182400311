import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useRouter } from 'next/dist/client/router'
import { Category } from 'shared-types/src/category'
import { GetProductListResponse, ProductList } from 'shared-types/src/product'
import Pagination from '~/components/Pagination/Pagination'
import { useSession } from '~/hooks/useSession/useSession'
import { Client } from '~/customClients/client'
import { GTM, GTMEvent } from '~/lib/gtm/GTM'
import { FilterInfo } from '../FilterProvider/FilterProvider'

//* ******************** *//
// *** Pagination Context *** //

export type PaginationInfo = {
  totalPages: number
  currentPage: number
  totalProducts: number
}

type NextPaginationArgs = {
  productCnt?: number
  filterInfo?: FilterInfo & {
    setProducts: Dispatch<SetStateAction<ProductList>>
  }
}

type PaginationContextType = {
  category?: Category
  pagination: PaginationInfo
  setPagination: Dispatch<SetStateAction<PaginationInfo>>
  renderPreviousPagination: () => React.JSX.Element
  renderNextPagination: (args: NextPaginationArgs) => React.JSX.Element
}

export const PaginationContext = createContext<PaginationContextType>(
  {} as PaginationContextType
)

export const usePaginationContext = () => {
  return useContext(PaginationContext)
}

//* ********************* *//
// *** Pagination Provider *** //

export type PaginationProviderInfo = {
  category?: Category
  totalPages: number
  currentPage: number
  totalProducts?: number
}

type PaginationProviderProps = PaginationProviderInfo & {
  children: ReactNode
}

export const PaginationProvider: FC<PaginationProviderProps> = ({
  category,
  totalPages: initialTotalPages,
  currentPage: initialPage,
  totalProducts: initialTotalProducts,
  children,
}) => {
  const [pagination, setPagination] = useState<PaginationInfo>({
    totalPages: initialTotalPages,
    currentPage: initialPage,
    totalProducts: initialTotalProducts,
  })
  const [nextPageIsLoading, setNextPageIsLoading] = useState<boolean>(false)
  const [prevTotalProducts, setPrevTotalProducts] =
    useState(initialTotalProducts)

  const router = useRouter()
  const { session } = useSession()

  const asPath = router?.asPath?.split('?')?.[0]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { slug, ...param } = router.query

  if (initialTotalProducts !== prevTotalProducts) {
    setPrevTotalProducts(initialTotalProducts)
    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        totalProducts: initialTotalProducts,
      }
    })
  }

  if (initialTotalPages !== pagination.totalPages) {
    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        totalPages: initialTotalPages,
      }
    })
  }

  const fetchNextPage = useCallback(
    async (args: NextPaginationArgs) => {
      const {
        filterInfo: {
          searchType,
          searchQuery,
          searchFilters,
          selectedSortOption,
          selectedFilterMap,
          setProducts,
        },
      } = args
      const filterParamValues = {}
      Object.keys(selectedFilterMap).forEach((key) => {
        if (key !== 'p') {
          filterParamValues[key] = selectedFilterMap[key].join('|')
        }
      })
      setNextPageIsLoading(true)
      const params = new URLSearchParams({
        p: String(pagination.currentPage + 1),
        ...(category?.url ? { slug: category?.url } : {}),
        ...filterParamValues,
        ...(selectedSortOption ? { sort: selectedSortOption } : {}),
        ...(searchQuery ? { q: `${searchQuery.selectedOptions}` } : {}),
      })
      try {
        const response = await Client.productList.getProductList(
          params,
          session
        )
        const {
          products = [],
          totalPages,
          currentPage,
          totalProducts,
        }: GetProductListResponse = response

        setPagination({ totalPages, currentPage, totalProducts })
        setProducts((prev: ProductList) => {
          return [...prev, ...products]
        })
        GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
        GTM.dispatch(GTMEvent.VIEW_ITEM_LIST, {
          products,
          category,
        })

        if (searchQuery) {
          GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
          GTM.dispatch(GTMEvent.VIEW_SEARCH_RESULTS, {
            products,
            searchTerm: searchQuery?.selectedOptions?.[0],
            searchResultCount: pagination.totalProducts ?? 0,
            searchFilters: searchFilters || 'NA',
            searchType,
          })
        }

        router.push(
          {
            pathname: asPath,
            query: {
              ...param,
              p: currentPage,
            },
          },
          null,
          { shallow: true }
        )
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
      setNextPageIsLoading(false)
    },
    [asPath, category, pagination, param, router, session]
  )

  const fetchPreviousPage = useCallback(() => {
    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        currentPage: prevPagination.currentPage - 1,
      }
    })
    router.push({
      pathname: asPath,
      query: {
        ...param,
        p: pagination.currentPage - 1,
      },
    })
  }, [asPath, pagination, param, router])

  const renderPreviousPagination = useCallback(() => {
    return (
      <Pagination
        onClick={fetchPreviousPage}
        showProgress
        totalPages={pagination.totalPages}
        action={{
          title: 'Load previous',
        }}
      />
    )
  }, [fetchPreviousPage, pagination])

  const renderNextPagination = useCallback(
    (args: NextPaginationArgs) => {
      const { productCnt, filterInfo } = args

      return pagination.currentPage < pagination.totalPages &&
        productCnt > 0 ? (
        <Pagination
          onClick={() => {
            !!filterInfo && fetchNextPage({ filterInfo })
          }}
          showingItemCount={productCnt}
          totalItemsCount={pagination.totalProducts}
          showProgress
          totalPages={pagination.totalPages}
          isLoading={nextPageIsLoading}
          action={{
            title: 'Load more',
          }}
        />
      ) : null
    },
    [fetchNextPage, nextPageIsLoading, pagination]
  )

  const value = useMemo(() => {
    return {
      category,
      pagination,
      setPagination,
      renderPreviousPagination,
      renderNextPagination,
    }
  }, [category, pagination, renderNextPagination, renderPreviousPagination])

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  )
}
