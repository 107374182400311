import { Link } from '@overdose/components'
import { IconShoppingBag } from '@tabler/icons-react'
import Card from '../Card/Card'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import { ProductSpecialOrderCardProps } from './ProductSpecialOrderCard.types'

export const ProductSpecialOrderCard = ({
  title,
  variant,
  url,
  description,
  linkText,
  onClick,
  openInNewWindow,
}: ProductSpecialOrderCardProps) => {
  return (
    <Card
      title={title}
      variant={variant}
      action={{ href: url, title, onClick, openInNewWindow }}
      icon={<IconShoppingBag size={24} />}>
      <Typography
        tag={TypographyTag.div}
        variant={TypographyVariant.BodyRegularExtraLineHeight}>
        {description}
        <Link
          to={url}
          title={title}
          className='underline'
          target={openInNewWindow ? '_blank' : '_self'}>
          {linkText}
        </Link>
      </Typography>
    </Card>
  )
}
