import Link from 'next/link'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { RelatedKeywordsProps } from './ProductListRelatedKeywords.types'

const ProductListRelatedKeywords = ({
  headingText,
  keywords,
}: RelatedKeywordsProps) => {
  return (
    <div className='text-primary-heading'>
      {headingText && (
        <Typography
          tag={TypographyTag.span}
          variant={TypographyVariant.Heading6}>
          {headingText}
        </Typography>
      )}
      <div className='flex flex-wrap gap-x-6 gap-y-4 mt-6'>
        {keywords?.map(({ href, label, count }, index) => {
          return (
            <Link key={index} className='leading-4' href={href}>
              <Typography
                tag={TypographyTag.span}
                variant={TypographyVariant.BodyRegularBold}>
                <span className='font-bold underline'>{label}</span>
              </Typography>
              <Typography
                tag={TypographyTag.span}
                className='ml-1'
                variant={TypographyVariant.BodyRegular}>
                ({count})
              </Typography>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default ProductListRelatedKeywords
