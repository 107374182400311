import React from 'react'
import Script from 'next/script'
import { BazaarVoiceProps } from './BazaarVoiceScript.types'

const BazaarVoiceScript = ({
  clientName,
  siteId,
  environment,
  locale,
}: BazaarVoiceProps) => {
  const bazaarVoiceEmbedUrl = `https://apps.bazaarvoice.com/deployments/${clientName}/${siteId}/${environment}/${locale}/bv.js`
  return <Script src={bazaarVoiceEmbedUrl} strategy='lazyOnload' />
}

export default BazaarVoiceScript
