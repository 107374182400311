import { ComposeSectionsProps } from '~/components/ComposeSections/ComposeSections.types'
import ErrorBoundary from '~/components/ErrorBoundary'
import sectionComponents from '~/sections'

export const ComposeSections = ({ sections, page }: ComposeSectionsProps) => {
  if (!sections) {
    return null
  }

  return (
    <>
      {sections.map((section, index) => {
        const { __typename, ...rest } = section
        const Component = sectionComponents[__typename]
        if (!Component) {
          console.error(`Section not registered: ${__typename}`)
          return null
        }
        return (
          <ErrorBoundary key={index} section={section}>
            <Component
              key={section.id}
              sectionPosition={index}
              page={page}
              {...rest}
            />
          </ErrorBoundary>
        )
      })}
    </>
  )
}
