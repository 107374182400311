import { IconUserCircle } from '@tabler/icons-react'
import { NavigationCardProps } from './NavigationCard.types'

export const data: NavigationCardProps = {
  title: 'Personal Info',
  description: 'Edit and manage account details',
  icon: <IconUserCircle size={36} color='#009FE3' />,
  action: {
    href: '/account/details',
    title: '',
  },
}
