/* eslint-disable react/no-danger */
import React from 'react'
import classNames from 'classnames'

import styles from './Stack.module.css'
import { StackProps } from './Stack.types'

export const Stack = ({ children, direction, spacing }: StackProps) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.horizontal]: direction === 'horizontal',
        [styles.vertical]: direction === 'vertical',
        [styles.gapXs]: spacing === 'xs',
        [styles.gapSm]: spacing === 'sm',
        [styles.gapMd]: spacing === 'md',
        [styles.gapLg]: spacing === 'lg',
      })}>
      {children}
    </div>
  )
}
