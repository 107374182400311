import { useState } from 'react'
import { IconBell } from '@tabler/icons-react'
import dynamic from 'next/dynamic'
import Card from '~/components/Card/Card'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { useSession } from '~/hooks'
import { GTM, GTMEvent } from '~/lib'
import { ProductBackInStockNotificationCardProps } from './ProductBackInStockNotificationCard.types'

const BackInStockNotificationSignUpDialogue = dynamic(
  async () => {
    const mod = await import(
      '~/components/BackInStockNotificationSignUpDialogue'
    )
    return mod.default
  },
  { ssr: false }
)

export const ProductBackInStockNotificationCard = ({
  product,
}: ProductBackInStockNotificationCardProps) => {
  const { session } = useSession()
  const [
    showBackInStockNotificationDialogue,
    setShowBackInStockNotificationDialogue,
  ] = useState(false)

  const notifyBackInStockEvent = () => {
    setShowBackInStockNotificationDialogue(true)
    GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
    GTM.dispatch(GTMEvent.NOTIFY_BACK_IN_STOCK, {
      product,
    })
  }

  return (
    <>
      <Card
        dataId='notifyWhenInStockButton'
        title={
          <Typography
            tag={TypographyTag.span}
            variant={TypographyVariant.BodyRegular}
            className='text-primary'>
            Notify me when back in Stock
          </Typography>
        }
        variant='info'
        action={
          session?.token?.isLoggedIn
            ? { onClick: notifyBackInStockEvent }
            : {
                title: 'Login and sign up for back in stock notifications',
                href: '#login',
                openInNewWindow: false,
              }
        }
        icon={<IconBell size={24} />}
      />
      {showBackInStockNotificationDialogue && (
        <BackInStockNotificationSignUpDialogue
          isOpen={showBackInStockNotificationDialogue}
          productCode={product?.sku}
          onClose={() => {
            setShowBackInStockNotificationDialogue(false)
          }}
        />
      )}
    </>
  )
}
