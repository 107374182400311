import { CustomerSavedVoucherCardProps } from './CustomerSavedVoucherCard.types'

const gap = 2

const now = new Date()
now.setDate(now.getDate() + gap)

export const data: CustomerSavedVoucherCardProps = {
  condition: 'Orders over $99',
  title: '$10 off next purchase* ',
  expiresAt: now.toISOString(),
}
