import classNames from 'classnames'
import RichText from '~/components/RichText/RichText'
import { SalePrice } from '~/components/SalePrice'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import styles from './HeroBanner.module.css'
import { HeroBannerTextContentProps } from './HeroBanner.types'

const richTextOptions = (textColor: string) => {
  return {
    customElementTypes: {
      p: (_attrs, children) => {
        return (
          <Typography
            variant={TypographyVariant.BodyLarge}
            tag={TypographyTag.p}
            className={classNames(textColor, styles.paragraph)}>
            {children}
          </Typography>
        )
      },
      h6: (_attrs, children) => {
        return (
          <Typography
            variant={TypographyVariant.BodyXLarge}
            tag={TypographyTag.p}
            className={classNames(textColor, styles.paragraph)}>
            {children}
          </Typography>
        )
      },
    },
  }
}

export const HeroBannerTextContent = ({
  title,
  paragraph,
  price,
  invert,
  label,
  priceText,
  saveMessage,
}: HeroBannerTextContentProps) => {
  const btnTextColor = invert ? '!text-primary-heading' : '!text-white'
  const textColor = invert ? '!text-primary-heading' : '!text-greyscale-50'
  return (
    <div className={classNames('flex flex-col', 'gap-2')}>
      {label && (
        <Typography
          tag={TypographyTag.p}
          variant={TypographyVariant.BodySmall}
          className={classNames('uppercase', textColor, styles.label)}>
          {label}
        </Typography>
      )}
      {title && (
        <div
          className={classNames(textColor, styles.title, {
            [styles.invertedColors]: invert,
          })}>
          <RichText
            content={title.content}
            options={richTextOptions(textColor)}
          />
        </div>
      )}
      {paragraph && (
        <RichText
          content={paragraph.content}
          options={richTextOptions(textColor)}
        />
      )}
      {price && (
        <SalePrice
          price={price}
          regularPriceClassName={classNames(
            btnTextColor,
            styles.regularPriceLabel
          )}
          savePriceClassName={classNames(styles.saveUpToButton)}
          saveUpToButtonStyle={{
            backgroundColor: saveMessage?.backgroundColor,
            textColor: saveMessage?.textColor,
          }}
          finalPriceClassName={btnTextColor}
          wasText={priceText}
          saveText={saveMessage?.text}
          truncateDecimalZeros
        />
      )}
    </div>
  )
}
