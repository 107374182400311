import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useIsVisible } from '@overdose/components'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Link from 'next/link'
import Script from 'next/script'
import { ProductCarouselSlide } from 'shared-types'
import { ProductImage } from '~/components'
import { Button } from '~/components/Button'
import { Cappasity3dSpinner } from '~/components/Cappasity3dSpinner'
import Card from '~/components/Card/Card'
import CardStack from '~/components/CardStack/CardStack'
import { Image } from '~/components/Image'
import { Price } from '~/components/Price'
import { ProductBackInStockNotificationCard } from '~/components/ProductBackInStockNotificationCard'
import { ProductBulkPricingCard } from '~/components/ProductBulkPricingCard'
import AddToListButton from '~/components/ProductCard/AddToListButton'
import ProductGallery from '~/components/ProductGallery/ProductGallery'
import { ProductShippingCardStack } from '~/components/ProductShippingCardStack'
import { ProductSpecialOfferCard } from '~/components/ProductSpecialOfferCard'
import { ProductSpecialOrderCard } from '~/components/ProductSpecialOrderCard'
import { ProductVariantOptionGroupComplex } from '~/components/ProductVariantOptionGroupComplex'
import { ProductVariantOptionGroupSimple } from '~/components/ProductVariantOptionGroupSimple'
import QuantitySelector from '~/components/QuantitySelector'
import { SectionContainer } from '~/components/SectionContainer'
import { Stack } from '~/components/Stack'
import Tag from '~/components/Tag'
import Typography, {
  TypographyVariant,
  TypographyTag,
} from '~/components/Typography'
import { getImageSrc } from '~/helpers'
import {
  useFormatPrice,
  useCart,
  useAlgoliaSearchInsignts,
  usePage,
  useCartDrawer,
} from '~/hooks'

import { DEFAULT_COUNTRY_CODE_NZ } from '~/constants/countries'
import { GTM, GTMEvent } from '~/lib'

import styles from './ProductMain.module.css'
import { PayLaterProvider, ProductMainProps } from './ProductMain.types'

let isNZSite = false
let isOverPriceThreshold = false
const LatitudeGemPayLaterWidget = dynamic(
  async () => {
    const mod = await import('./LatitudeGemPayLaterWidget')
    return mod.LatitudeGemPayLaterWidget
  },
  {
    loading: () => {
      return (
        <div
          className={classNames('h-full', {
            [styles.latitudePayLater]: !isNZSite && !isOverPriceThreshold,
            [styles.gemPayLater]: isNZSite && !isOverPriceThreshold,
            [styles.gemPayLater_price_over_threshold]:
              isNZSite && isOverPriceThreshold,
            [styles.latitudePayLater_price_over_threshold]:
              !isNZSite && isOverPriceThreshold,
          })}
        />
      )
    },
    ssr: false,
  }
)

export const ProductMain = ({
  product,
  width = 'wide',
  deliverToPostcodeAvailabilityStatus,
  storeAvailabilityStatus,
  specialCard,
  payLaterProviders,
  userCanSubscribeToInventoryNotifications = false,
}: ProductMainProps) => {
  const {
    title,
    brandImage,
    carousel,
    catNo,
    sku,
    mpn,
    finalPrice,
    regularPrice,
    tags,
    multiBuyTiers = [],
    variants,
    inStock,
    ratingCount,
  } = product
  const hasVariants = variants?.length > 0

  // Ensure clicking on Reviews Link scroll the reviews section in view
  useEffect(() => {
    document.querySelector('html').classList.add('scroll-padTop')

    return () => {
      document.querySelector('html').classList.remove('scroll-padTop')
    }
  }, [])

  const cartRef = useRef<HTMLDivElement>(null)
  const mobileCartRef = useRef<HTMLDivElement>(null)
  const [productQuantity, setProductQuantity] = useState<number>(1)
  const { formatPrice } = useFormatPrice()
  const isCartVisible = useIsVisible(cartRef)
  const { addToCart } = useCart()
  const { openCartDrawer } = useCartDrawer()
  const { latestQueryId, sendAddToCartEvent, removeQueryIDFromStorage } =
    useAlgoliaSearchInsignts()
  const wasCalled = useRef(false)
  const filteredTiers = multiBuyTiers?.filter((tier) => {
    return tier.finalPrice?.centAmount !== finalPrice.centAmount
  })
  const [mobileCartHeight, setMobileCartHeight] = useState<number>(0)
  const [zendeskButtonIframe, setZendeskButtonIframe] =
    useState<HTMLElement>(null)
  const [zendeskChatIframe, setZendeskChatIframe] = useState<HTMLElement>(null)

  const handleAddToCart = useCallback(() => {
    addToCart.mutate(
      [{ sku, quantity: productQuantity || 1, algoliaQueryId: latestQueryId }],
      {
        onSuccess(data) {
          if (data) {
            GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
            GTM.dispatch(GTMEvent.CART_CONTENTS_PUSH, data)
            sendAddToCartEvent(data.lineItems, data.subTotal, latestQueryId)
          }
          openCartDrawer()
          removeQueryIDFromStorage()
        },
      }
    )
  }, [
    addToCart,
    sku,
    productQuantity,
    openCartDrawer,
    removeQueryIDFromStorage,
    sendAddToCartEvent,
    latestQueryId,
  ])

  const page = usePage()
  const isRTM = page?.brand === 'rtm'
  isNZSite = page.countryCode === DEFAULT_COUNTRY_CODE_NZ
  const latitudePrice = payLaterProviders.find((bnpl) => {
    return bnpl.name === 'LatitudeGemPayLater'
  })?.price
  isOverPriceThreshold = Number(latitudePrice) > Number('250')

  const cartGroup = () => {
    return (
      <div className='flex items-center gap-2 max-[374px]:flex-wrap'>
        <div className={styles.qty}>
          <QuantitySelector
            minValue={1}
            maxValue={product?.maxQuantity || 1}
            defaultValue={1}
            step={1}
            isLoading={false}
            onChange={(qty) => {
              return setProductQuantity(qty)
            }}
            className='w-[117px]'
          />
        </div>
        <Button
          data-id='addToCartButton'
          status='primary'
          theme={{ root: 'flex-1' }}
          onClick={handleAddToCart}
          loading={addToCart.isLoading}
          disabled={!inStock}>
          {inStock ? 'Add to cart' : 'Sold Out'}
        </Button>
        <AddToListButton
          product={product}
          iconSize={20}
          className={styles.listBtn}
          buttonProps={{
            status: 'secondary',
            variant: 'solid',
            iconOnly: true,
            style: {
              '--btn-icon-size': '20px',
              width: '52px',
            } as CSSProperties,
          }}
        />
      </div>
    )
  }

  const ProductGalleryRef = useRef(null)
  const Cappasity3dSpinnerRef = useRef(null)
  const [isCappasity3dSpinnerSeen, setIsCappasity3dSpinnerSeen] =
    useState(false)

  useEffect(() => {
    if (ProductGalleryRef.current && Cappasity3dSpinnerRef.current) {
      const options = {
        root: ProductGalleryRef.current,
        rootMargin: '0px',
        threshold: 0.1,
      }
      const target = Cappasity3dSpinnerRef.current

      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setIsCappasity3dSpinnerSeen(true)
          observer.unobserve(target)
        }
      }, options)

      observer.observe(target)

      return () => {
        observer.unobserve(target)
      }
    }

    return () => {}
  }, [])

  const getZendeskComponentsFromDocument = () => {
    const buttonIframe = document.getElementById('launcher')
    const chatContainer = buttonIframe?.parentElement?.previousElementSibling
    const chatIframe = chatContainer?.querySelector('iframe')

    return {
      buttonIframe,
      chatIframe,
    }
  }

  const setZendeskComponents = (
    buttonIframe: HTMLElement,
    chatIframe: HTMLElement
  ) => {
    setZendeskButtonIframe(buttonIframe)
    setZendeskChatIframe(chatIframe)
  }

  useEffect(() => {
    let zendeskMutationObserver: MutationObserver
    let { buttonIframe, chatIframe } = getZendeskComponentsFromDocument()

    if (buttonIframe != null) {
      setZendeskComponents(buttonIframe, chatIframe)
    } else {
      zendeskMutationObserver = new MutationObserver(() => {
        ;({ buttonIframe, chatIframe } = getZendeskComponentsFromDocument())

        setZendeskComponents(buttonIframe, chatIframe)

        if (buttonIframe != null && chatIframe != null) {
          zendeskMutationObserver?.disconnect()
        }
      })

      zendeskMutationObserver.observe(document.body, {
        childList: true,
        subtree: true,
      })
    }

    return () => {
      zendeskMutationObserver?.disconnect()
    }
  }, [])

  useEffect(() => {
    let mobileCartResizeObserver: ResizeObserver

    if (mobileCartRef.current) {
      mobileCartResizeObserver = new ResizeObserver(() => {
        const newHeight =
          mobileCartRef.current?.getBoundingClientRect()?.height || 0
        setMobileCartHeight(newHeight)
      })

      mobileCartResizeObserver.observe(mobileCartRef.current)
    }

    return () => {
      mobileCartResizeObserver?.disconnect()
    }
  }, [])

  useEffect(() => {
    const zendeskParent = zendeskButtonIframe?.parentElement?.parentElement
    zendeskParent?.style?.setProperty(
      '--mobile-cart-height',
      `${mobileCartHeight}px`
    )
    zendeskButtonIframe?.classList?.add(styles.zendeskButton)
    zendeskChatIframe?.classList?.add(styles.zendeskChat)

    if (isCartVisible) {
      zendeskButtonIframe?.classList?.remove(styles.zendeskButton)
      zendeskChatIframe?.classList?.remove(styles.zendeskChat)
    }

    return () => {
      zendeskButtonIframe?.classList?.remove(styles.zendeskButton)
      zendeskChatIframe?.classList?.remove(styles.zendeskChat)
    }
  }, [zendeskButtonIframe, zendeskChatIframe, isCartVisible, mobileCartHeight])

  const slides = useMemo(() => {
    const slides = []

    if (carousel?.slides) {
      carousel?.slides?.forEach((slide: ProductCarouselSlide, index) => {
        if (slide.type !== 'image') {
          return
        }
        slides.push(
          <div
            key={index}
            className={classNames(
              styles.gallery,
              'relative md:!h-full w-full'
            )}>
            {slide?.src && slide?.altText && (
              <ProductImage
                className='w-full h-full'
                src={slide?.src}
                alt={slide.altText}
                // width and height required for SEO
                width={slide.width}
                height={slide.height}
                priority={slides.length === 0}
                style={{ objectFit: 'contain' }}
                sizes='(min-width: 1280px) 720px, (min-width: 768px) 651px, 100vw'
              />
            )}
          </div>
        )
      })
    }

    if (carousel?.cappasityModelId) {
      slides.push(
        <div className='flex m-5 w-full'>
          <Card>
            <Cappasity3dSpinner
              cappasityModelId={carousel.cappasityModelId}
              isSeen={isCappasity3dSpinnerSeen}
              ref={Cappasity3dSpinnerRef}
            />
          </Card>
        </div>
      )
    }

    return slides
  }, [carousel.cappasityModelId, carousel?.slides, isCappasity3dSpinnerSeen])

  useEffect(() => {
    if (!product || wasCalled.current) {
      return
    }
    wasCalled.current = true
    GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
    GTM.dispatch(GTMEvent.VIEW_ITEM, { product })
  }, [product])

  const renderPayLaterProviders = (offer: PayLaterProvider) => {
    const isAfterPayLater = offer?.name === 'AfterPayLater'
    const hasRequiredFields = isAfterPayLater || Boolean(offer.merchantId)

    if (!offer.price || !hasRequiredFields) {
      return null
    }

    switch (offer.name) {
      case 'AfterPayLater': {
        const url = 'https://js.afterpay.com/afterpay-1.x.js'
        return (
          <div className='[&>*]:m-0'>
            {/* @ts-ignore */}
            <afterpay-placement
              data-locale={offer.locale || 'en_AU'}
              data-currency={offer.currency || 'AUD'}
              data-amount={offer.price}
              data-is-eligible='true'
              data-size='sm'
              data-logo-type='compact-badge'
              data-show-lower-limit='false'
            />
            <Script
              src={url}
              async
              data-analytics-enabled
              data-min={offer.minAmount || ''}
              data-max={offer.maxAmount || ''}
            />
          </div>
        )
      }
      case 'HummPayLater': {
        const url = `https://bpi.humm-au.com/au/content/scripts/price-info_sync.js?productPrice=${offer.price}&merchantId=${offer.merchantId}`
        return (
          <div
            className={classNames(
              styles.hummPayLater,
              styles.bnplWidgetWrapper
            )}>
            <Head>
              <link rel='preconnect' href={url} />
              <link rel='prefetch' href={url} as='script' />
            </Head>
            <Script src={url} strategy='lazyOnload' defer />
            <div className='humm-price-info-widget' />
          </div>
        )
      }
      case 'ZipPayLater': {
        const url = 'https://cdn.us.zip.co/v1/quadpay.js'
        return (
          <div className={styles.bnplWidgetWrapper}>
            <Head>
              <link rel='dns-prefetch' href={url} />
              <link rel='prefetch' href={url} as='script' />
            </Head>
            <Script src={url} strategy='lazyOnload' defer />
            {/* @ts-ignore */}
            <quadpay-widget-v3
              amount={offer.price}
              merchantId={offer.merchantId}
            />
          </div>
        )
      }
      case 'LatitudeGemPayLater': {
        return (
          <LatitudeGemPayLaterWidget offer={offer} key={offer.product.id} />
        )
      }
      default:
        return null
    }
  }

  return (
    <>
      <SectionContainer
        width={width}
        wrapperClassName={classNames(
          'md:sticky top-20 z-50 bg-white !mt-6 md:!mt-4 2xl:!mt-5 pdp-header md:top-[140px]',
          isRTM || page.countryCode === DEFAULT_COUNTRY_CODE_NZ
            ? 'lg:top-[140px]'
            : ''
        )}>
        <div className='flex items-start md:items-center min-h-[80px]'>
          {brandImage?.src && brandImage?.href && (
            <Link href={brandImage.href} className='hidden'>
              <div
                className={classNames(
                  styles.brand,
                  'mr-2.5 lg:mr-4 p-1 rounded lg:rounded-md'
                )}>
                <div className='relative h-full overflow-hidden'>
                  <Image
                    src={getImageSrc(brandImage.src, '100')}
                    addSrcSet={false}
                    alt={brandImage.altText}
                    fill
                    style={{ objectFit: 'contain' }}
                    sizes='80px'
                  />
                </div>
              </div>
            </Link>
          )}

          <div className='flex-1'>
            {title && (
              <Typography
                tag={TypographyTag.h1}
                variant={TypographyVariant.Heading4}
                className={styles.title}>
                {title}
              </Typography>
            )}
            <div className='flex md:gap-6 flex-col md:flex-row'>
              {(catNo || mpn) && (
                <Typography
                  variant={TypographyVariant.BodyRegular}
                  tag={TypographyTag.div}
                  className='text-greyscale-600 mt-2'>
                  <ul className='flex flex-wrap items-center justify-between md:justify-start gap-x-6'>
                    {catNo && (
                      <li className='whitespace-nowrap font-bold'>
                        CAT.NO: {catNo}
                      </li>
                    )}
                    {mpn && (
                      <li className='whitespace-nowrap font-bold'>
                        MPN: {mpn}
                      </li>
                    )}
                  </ul>
                </Typography>
              )}
              {!!ratingCount && (
                <Link href='#reviews' className='mt-2'>
                  <div
                    className='rating_summary'
                    data-bv-show='rating_summary'
                    data-bv-product-id={sku}
                  />
                </Link>
              )}
            </div>
          </div>
          <div
            className={classNames(
              styles.rightSide,
              styles.cartWrap,
              'hidden md:block'
            )}>
            {cartGroup()}
          </div>
        </div>
      </SectionContainer>
      <SectionContainer
        width='wide'
        wrapperClassName='md:bg-background-thumb md:pb-0 overflow-x-hidden'>
        <div className='relative md:flex'>
          <div className={classNames(styles.leftSide, 'flex-1')}>
            <div
              className={classNames(
                styles.fullWidth,
                'md:!relative md:!w-full bg-background-thumb'
              )}>
              <ProductGallery
                slides={slides}
                show360Icon={!!carousel?.cappasityModelId}
                ref={ProductGalleryRef}
              />

              <div className='absolute left-0 top-4 md:top-8 xl:top-12 z-10'>
                <Stack direction='horizontal' spacing='xs'>
                  {tags?.map((tag, index) => {
                    return (
                      <Tag
                        variant='secondary'
                        key={index}
                        color={tag.color}
                        backgroundColor={tag.backgroundColor}
                        theme={{ pending: 'border-0 h-6' }}>
                        <span
                          className={classNames(
                            styles.tag,
                            'md:!text-sm md:!leading-4 block uppercase'
                          )}>
                          {tag.desc}
                        </span>
                      </Tag>
                    )
                  })}
                </Stack>
              </div>
            </div>
          </div>
          <div className={classNames(styles.rightSide, 'py-6 md:py-8')}>
            <Price
              tag={TypographyTag.div}
              variant={TypographyVariant.BodyLarge}
              finalPrice={finalPrice}
              regularPrice={regularPrice}
              displaySaveOnNewLine
              offerExpiresAt={finalPrice?.validUntil}
            />
            <div ref={cartRef} className='md:hidden mt-6'>
              {cartGroup()}
            </div>
            <div className='mt-6 md:mt-4'>
              {payLaterProviders?.map((offer, index) => {
                return (
                  <Typography
                    key={index}
                    tag={TypographyTag.div}
                    variant={TypographyVariant.BodyRegularExtraLineHeight}
                    className={classNames(
                      'flex items-center',
                      styles.paylaterProviderWrapper,
                      {
                        'mt-3 md:mt-1': index !== 0,
                      }
                    )}>
                    {renderPayLaterProviders(offer)}
                  </Typography>
                )
              })}
            </div>

            <div className='mt-3'>
              <Stack direction='vertical' spacing='xs'>
                {filteredTiers.length > 0 && (
                  <ProductBulkPricingCard
                    tiers={[
                      {
                        minimumQuantity: 1,
                        finalPrice,
                      },
                      ...filteredTiers,
                    ]}
                    isCompact={hasVariants}
                  />
                )}

                {hasVariants && (
                  <CardStack className='w-full'>
                    {product.variants.map((variantOption, index) => {
                      const isComplex = variantOption.type === 'complex'
                      return isComplex ? (
                        <ProductVariantOptionGroupComplex
                          {...variantOption}
                          key={index}
                        />
                      ) : (
                        <ProductVariantOptionGroupSimple
                          {...variantOption}
                          key={index}
                        />
                      )
                    })}
                  </CardStack>
                )}

                <ProductShippingCardStack
                  deliverToPostcodeAvailabilityStatus={
                    deliverToPostcodeAvailabilityStatus
                  }
                  storeAvailabilityStatus={storeAvailabilityStatus}
                  sku={sku}
                  productPrice={finalPrice.centAmount / 100}
                />

                {specialCard && (
                  <>
                    {specialCard?.cardType === 'special-offer' && (
                      <ProductSpecialOfferCard {...specialCard} />
                    )}
                    {specialCard?.cardType === 'special-order' && (
                      <ProductSpecialOrderCard {...specialCard} />
                    )}
                  </>
                )}

                {!inStock && userCanSubscribeToInventoryNotifications && (
                  <ProductBackInStockNotificationCard product={product} />
                )}
              </Stack>
            </div>
          </div>
        </div>

        <div
          ref={mobileCartRef}
          className={classNames(
            styles.cartPop,
            'fixed bottom-0 left-0 z-50 w-full bg-white md:hidden',
            isCartVisible ? 'hidden' : 'block'
          )}>
          <div className='flex items-start justify-between mb-2 w-full'>
            <div className='flex-1 text-left'>
              <Typography
                tag={TypographyTag.div}
                variant={TypographyVariant.BodyRegularBold}>
                {title}
              </Typography>
            </div>
            <div className='flex-1 text-right'>
              <Typography
                tag={TypographyTag.div}
                variant={TypographyVariant.BodyRegularBold}>
                {formatPrice(product.finalPrice)}
              </Typography>
            </div>
          </div>
          {cartGroup()}
        </div>
      </SectionContainer>
    </>
  )
}
