import { IconAddressBook, IconCreditCard } from '@tabler/icons-react'
import { data as customerSavedVoucherMock } from '../CustomerSavedVoucherCard/CustomerSavedVoucherCard.mock'
import { CustomerSavedVoucherCardProps } from '../CustomerSavedVoucherCard/CustomerSavedVoucherCard.types'
import { NavigationCardProps } from '../NavigationCard'
import { data as navigationCardMock } from '../NavigationCard/NavigationCard.mock'
import { orderDeliveryCardMock } from '../OrderDeliveryCard/OrderDeliveryCard.mock'

export const navigationCardData1 = { ...navigationCardMock }
delete navigationCardData1.description

export const navigationCardData2: NavigationCardProps = {
  ...navigationCardData1,
  title: 'Address Book',
  icon: <IconAddressBook size={36} color='#009FE3' />,
  action: {
    href: '/account/addresses',
    title: '',
  },
}

export const navigationCardData3: NavigationCardProps = {
  ...navigationCardData1,
  title: 'Payment Methods',
  icon: <IconCreditCard size={36} color='#009FE3' />,
  action: {
    href: '/account/payment',
    title: '',
  },
}

export const customerSavedVoucherCard2: CustomerSavedVoucherCardProps = {
  ...customerSavedVoucherMock,
  title: 'Take 5% off',
}

export const accountQuickViewDrawerMock = {
  customerRecentOrders: [
    orderDeliveryCardMock,
    orderDeliveryCardMock,
    orderDeliveryCardMock,
  ],
  navigation: [navigationCardData1, navigationCardData2, navigationCardData3],
}
