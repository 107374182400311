import {
  DeliveryStatus,
  DeliveryType,
  OrderDeliveryCardProps,
} from 'shared-types'

export const orderClickAndCollectCardMock: OrderDeliveryCardProps = {
  productsCount: 3,
  orderNumber: 'ABC12345',
  deliveryType: DeliveryType.PICKUP,
  status: DeliveryStatus.ReadyToCollect,
  predictedDeliveryAtRange: {
    from: new Date('2023-04-19'),
    to: new Date('2023-04-24'),
  },
}

export const orderDeliveryCardMock: OrderDeliveryCardProps = {
  ...orderClickAndCollectCardMock,
  deliveryType: DeliveryType.DELIVERY,
  status: DeliveryStatus.Processing,
}
