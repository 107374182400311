import { useState } from 'react'
import { VisuallyHidden } from '@overdose/components'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { ProductVariantOptionGroupComplexProps } from 'shared-types/src/variants'
import { getImageSrc } from '~/helpers'
import Card from '../Card/Card'
import { Price } from '../Price'
import { ProductImage } from '../ProductImage'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './ProductVariantOptionGroupComplex.module.scss'

const Drawer = dynamic(
  async () => {
    const mod = await import('../Drawer')
    return mod.Drawer
  },
  { ssr: false }
)

export const ProductVariantOptionGroupComplex = ({
  values,
  title,
}: ProductVariantOptionGroupComplexProps) => {
  const [open, setOpen] = useState(false)
  const valuesLength = values?.length
  const toggleDrawer = () => {
    setOpen(!open)
  }

  const drawerProps = {
    theme: { header: classNames('px-6', styles.drawerHeader) },
    title: (
      <Typography
        variant={TypographyVariant.BodyLargeBold}
        tag={TypographyTag.p}
        className='text-primary-heading'>
        {title} ({valuesLength})
      </Typography>
    ),

    isOpen: open,
    onClose: toggleDrawer,
    children: (
      <div className='p-6 overflow-auto'>
        {!!valuesLength && (
          <div className={classNames('flex gap-4 flex-col')}>
            {values.map(
              ({
                selected,
                title,
                url,
                finalPrice,
                thumbnail,
                regularPrice,
                attributes,
              }) => {
                const { altText, height, src, width } = thumbnail
                return (
                  <div
                    key={url}
                    className={classNames(
                      'flex border rounded-md border-primary-border p-4 gap-2 relative h-max',
                      { 'border-text-primary': selected }
                    )}>
                    <div className='bg-background-thumb p-2 rounded-md w-[84px] h-[84px] overflow-hidden'>
                      <ProductImage
                        src={getImageSrc(src, '68')}
                        addSrcSet={false}
                        alt={altText}
                        width={width}
                        height={height}
                        className='h-full w-full'
                      />
                    </div >
                    <div className='flex flex-col justify-between'>
                      <Typography
                        tag={TypographyTag.p}
                        variant={TypographyVariant.BodyRegularBold}
                        className={classNames(styles.cardTitle)}>
                        {title}
                      </Typography>

                      {!!attributes?.length && (
                        <div className='flex flex-col flex-1'>
                          {attributes.map((attr, id) => {
                            return (
                              <Typography
                                key={id}
                                tag={TypographyTag.p}
                                variant={
                                  TypographyVariant.BodySmallExtraLineHeightBold
                                }
                                className={classNames(styles.cardTitle)}>
                                {attr.name}: {attr.value}
                              </Typography>
                            )
                          })}
                        </div>
                      )}
                      <Price
                        tag={TypographyTag.p}
                        variant={TypographyVariant.BodyLargeBold}
                        finalPrice={finalPrice}
                        regularPrice={regularPrice}
                        finalPriceClassName='text-lg leading-6'
                        regularPriceClassName='text-sm leading-4'
                        savePriceClassName='text-lg leading-6'
                        offerClassName={classNames({
                          '!hidden':
                            regularPrice?.centAmount === finalPrice?.centAmount,
                        })}
                      />
                    </div>
                    <Link
                      href={url}
                      className='absolute inset-0'
                      title={`Link to ${title}`}>
                      <VisuallyHidden>Link to {title}</VisuallyHidden>
                    </Link>
                  </div >
                )
              }
            )}
          </div >
        )}
      </div >
    ),
  }

  return (
    <>
      <Card
        action={{ onClick: toggleDrawer }}
        title={
          <Typography
            variant={TypographyVariant.BodyRegularBold}
            tag={TypographyTag.span}>
            {title} ({values?.length})
          </Typography>
        }
        variant='default'
      />
      <Drawer
        {...drawerProps}
        placement='bottom'
        wrapperClass='md:hidden block'
      />
      <Drawer {...drawerProps} wrapperClass='md:block hidden' />
    </>
  )
}
