import { useCallback, useEffect, useState } from 'react'
import { CustomLocation } from 'shared-types'
import Input from '~/components/Input'
import { useDebounce, usePage, useUserLocation } from '~/hooks'
import styles from './LocationSearch.module.css'
import { LocationSearchProps } from './LocationSearch.types'

const DEBOUNCE_DELAY = 500

export const LocationSearch = <
  TFormValues extends Record<string, unknown> = Record<string, unknown>
>({
  type = 'address',
  onChange,
  onLocationSelected,
  ...inputProps
}: LocationSearchProps<TFormValues>) => {
  const [open, setOpen] = useState(false)
  const [suggestions, setSuggestions] = useState<CustomLocation[]>([])
  const { countryCode } = usePage()
  const [keyword, setKeyword] = useState('')
  const debouncesKeyword = useDebounce(keyword, DEBOUNCE_DELAY)
  const { geolocateFromInternalService } = useUserLocation()
  const [focusingItemIdx, setFocusingItemIdx] = useState(0)

  const popupVisible = open && suggestions.length > 0

  const fetchSuggestions = useCallback(
    async (key: string) => {
      const results = await geolocateFromInternalService(key, countryCode)
      setSuggestions(results)
      setFocusingItemIdx(0)
    },
    [
      countryCode,
      geolocateFromInternalService,
      setSuggestions,
      setFocusingItemIdx,
    ]
  )

  const handleChange = useCallback(
    (value: string) => {
      setKeyword(value)
      onChange?.(value)

      if (!value) {
        setSuggestions([])
      }
    },
    [setKeyword, setSuggestions, onChange]
  )

  const handleSelected = useCallback(
    (selected?: CustomLocation) => {
      if (!selected) {
        return
      }

      if (type === 'postcode') {
        setKeyword(selected.postcode)
      } else {
        setKeyword(`${selected.locality} ${selected.postcode}`)
      }

      onLocationSelected(selected)
      setOpen(false)
    },
    [suggestions, type, onLocationSelected, setOpen]
  )

  const handleKeydown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!popupVisible) {
        return
      }

      switch (e.key) {
        case 'Enter':
          handleSelected(suggestions[focusingItemIdx])
          break
        case 'ArrowUp':
          if (focusingItemIdx > 0) {
            setFocusingItemIdx(focusingItemIdx - 1)
          }
          break
        case 'ArrowDown':
          if (focusingItemIdx < suggestions.length - 1) {
            setFocusingItemIdx(focusingItemIdx + 1)
          }
          break
        default:
          break
      }
    },
    [
      focusingItemIdx,
      popupVisible,
      suggestions,
      handleSelected,
      setFocusingItemIdx,
    ]
  )

  useEffect(() => {
    if (!debouncesKeyword) {
      return
    }

    fetchSuggestions(debouncesKeyword)
  }, [debouncesKeyword, fetchSuggestions])

  useEffect(() => {
    setKeyword(inputProps.value || '')
  }, [inputProps.value])

  return (
    <div className={styles.wrapper}>
      <Input
        {...inputProps}
        theme={{ inner: styles.input }}
        autocomplete='off'
        value={keyword}
        onFocus={() => {
          setOpen(true)
        }}
        onBlur={() => {
          setTimeout(() => {
            setOpen(false)
          }, DEBOUNCE_DELAY)
        }}
        onClick={() => {
          setOpen(true)
        }}
        onChange={handleChange}
        onKeyDown={handleKeydown}
      />
      {popupVisible && (
        <div className={styles.dropdown}>
          <ul>
            {suggestions.map((sg, index) => {
              return (
                <li key={index}>
                  <button
                    role='button'
                    className={focusingItemIdx === index && styles.focused}
                    onClick={() => {
                      setFocusingItemIdx(index)
                      handleSelected(sg)
                    }}>
                    {sg.locality} {sg.postcode}
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}
