import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './SkipLink.module.css'
import { SkipLinkProps } from './SkipLink.types'

const SkipLink = ({
  title = 'Skip to content',
  to = '#main-content',
  dataTestId,
}: SkipLinkProps) => {
  return (
    <a data-test-id={dataTestId} href={to} className={styles.skipLink}>
      <Typography tag={TypographyTag.p} variant={TypographyVariant.BodyRegular}>
        {title}
      </Typography>
    </a>
  )
}
export default SkipLink
