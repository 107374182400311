import { useState } from 'react'
import { VisuallyHidden } from '@overdose/components'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import type { Swiper } from 'swiper'
import { Button } from '~/components'
import { SectionContainer } from '~/components/SectionContainer'
import { FeatureHeroBanner } from './FeatureHeroBanner'
import styles from './HeroBanner.module.css'
import { HeroBannerProps } from './HeroBanner.types'
import { HeroBannerTextContent } from './HeroBannerTextContent'

let sectionProps: HeroBannerProps
const HeroBannerCarousel = dynamic(
  async () => {
    const mod = await import('./HeroBannerCarousel')
    return mod.HeroBannerCarousel
  },
  {
    ssr: false,
    loading: () => {
      if (!sectionProps?.slides?.[0]) {
        return null
      }

      return (
        <FeatureHeroBanner {...sectionProps?.slides?.[0]}>
          <HeroBannerTextContent
            invert={sectionProps?.slides?.[0]?.invert}
            paragraph={sectionProps?.slides?.[0]?.paragraph}
            price={sectionProps?.slides?.[0]?.price}
            title={sectionProps?.slides?.[0]?.title}
          />
        </FeatureHeroBanner>
      )
    },
  }
)

const HeroBanner = ({ width = 'full', ...props }: HeroBannerProps) => {
  sectionProps = props
  const [swiper, setSwiper] = useState<Swiper | null>(null)

  const handleSlidePrev = () => {
    if (swiper) {
      swiper.slidePrev()
    }
  }

  const handleSlideNext = () => {
    if (swiper) {
      swiper.slideNext()
    }
  }

  return (
    <SectionContainer
      width={width}
      innerClassName='p-0 relative'
      wrapperClassName={styles.sectionWrapper}>
      {props?.slides?.length > 0 ? (
        <div className={classNames('relative', styles.container)}>
          <HeroBannerCarousel setSwiper={setSwiper} slides={props.slides} />
          <div
            className='flex gap-12 absolute z-10 bottom-7 justify-center w-full'
            style={{ gap: `calc(16px * ${props.slides.length})` }}>
            <Button
              iconOnly
              variant='ghost'
              style={{
                '--btn-icon-size': '20px',
                '--btn-ghost-color-text-primary': '#FFFFFF',
              }}
              theme={{
                root: 'z-20',
              }}
              size='xs'
              aria-label='previous'
              onClick={handleSlidePrev}
              icon={<IconChevronLeft size={20} />}>
              <VisuallyHidden>Previous</VisuallyHidden>
            </Button>
            <Button
              iconOnly
              variant='ghost'
              style={{
                '--btn-icon-size': '20px',
                '--btn-ghost-color-text-primary': 'white',
              }}
              theme={{
                root: 'z-20',
              }}
              size='xs'
              aria-label='next'
              onClick={handleSlideNext}
              icon={<IconChevronRight size={20} />}>
              <VisuallyHidden>Next</VisuallyHidden>
            </Button>
          </div>
        </div>
      ) : (
        <FeatureHeroBanner {...props}>
          <HeroBannerTextContent
            invert={props.invert}
            paragraph={props.paragraph}
            price={props.price}
            title={props.title}
            label={props?.label}
            priceText={props?.priceText}
            saveMessage={props?.saveMessage}
          />
        </FeatureHeroBanner>
      )}
      {!props?.action?.isDisabled && (
        <Link href={props?.action?.href} className='absolute inset-0'>
          <VisuallyHidden>{props?.action?.accessibleTitle}</VisuallyHidden>
        </Link>
      )}
    </SectionContainer>
  )
}

export { HeroBanner }
