import { Fragment, ReactElement } from 'react'
import { Attributes } from '@contentstack/utils'
import { Link } from '@overdose/components'
import classNames from 'classnames'
import {
  IJsonToElementOptions,
  IJsonToElementTags,
  IJsonToElementTextTags,
  RichTextAnyNode,
  RichTextCustomNode,
  RichTextNode,
} from 'shared-types'
import { Image } from '~/components/Image'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { VideoPlayer } from '../VideoPlayer'
import styles from './RichText.module.css'
import { RichTextProps } from './RichText.types'

interface CotnentImageProps {
  attrs: Partial<Attributes>
}

interface ReferencedImageProps {
  attrs: Partial<Attributes>
}

const YOUTUBE_REGEX =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/

const validateEmbedAttrs = (attrs: Partial<Attributes>) => {
  let validAttrs = { ...attrs }

  const hasHeight = !!validAttrs.height || !!validAttrs.style?.height
  const isYoutube = validAttrs?.src && YOUTUBE_REGEX.test(validAttrs?.src)

  if (isYoutube && !hasHeight) {
    validAttrs = {
      ...validAttrs,
      class: classNames(validAttrs.class, 'aspect-video max-w-full !h-auto'),
    }
  }

  return validAttrs
}

const ContentImage = ({ attrs }: CotnentImageProps) => {
  const imageAttrs: Partial<Attributes> = {
    ...attrs,
    class: `${attrs.class || ''} !max-w-full`,
  }

  if (attrs?.src || attrs?.url) {
    if (
      (attrs.width || attrs.style?.width) &&
      (attrs.height || attrs.style?.height)
    ) {
      return (
        <Image
          {...imageAttrs}
          src={attrs.src}
          alt={attrs.alt}
          width={attrs.width || attrs.style?.width}
          height={attrs.height || attrs.style?.height}
        />
      )
    }

    return (
      /* eslint-disable-next-line @next/next/no-img-element */
      <img
        {...imageAttrs}
        src={attrs.src ?? attrs.url}
        alt={attrs.alt}
        width={attrs.width || attrs.style?.width}
        height={attrs.height || attrs.style?.height}
      />
    )
  }
  /* eslint-disable-next-line @next/next/no-img-element */
  return <img {...attrs} src={attrs.src} alt={attrs.alt} loading='lazy' />
}

const ReferencedImage = ({ attrs }: ReferencedImageProps) => {
  const renderImage = () => {
    const imageAttrs: Partial<Attributes> = {
      style: {
        width: attrs?.style?.width || 'auto',
        height: attrs?.style?.height || 'auto',
      },
    }

    const floatPosition = attrs.inline ? attrs.position : null

    let picture = (
      <picture
        className={classNames({
          'align-top mb-2': floatPosition != null,
          'mr-2': floatPosition === 'left',
          'ml-2': floatPosition === 'right',
        })}
        style={{
          float: floatPosition,
        }}>
        <img
          loading='lazy'
          className={classNames('!max-w-full inline-block', attrs.class)}
          src={attrs.assetLink}
          alt={attrs.alt}
          {...imageAttrs}
        />
      </picture>
    )

    if (attrs.anchorLink || attrs.link) {
      picture = (
        <Link
          to={attrs.anchorLink || attrs.link}
          target={attrs.target ? '_blank' : null}>
          {picture}
        </Link>
      )
    }

    return picture
  }

  const renderImageCaption = () => {
    if (!attrs.assetCaption) {
      return null
    }

    return (
      <figcaption className='text-center'>
        <Typography
          variant={TypographyVariant.BodySmall}
          tag={TypographyTag.span}>
          {attrs.assetCaption}
        </Typography>
      </figcaption>
    )
  }

  if (attrs.inline) {
    return renderImage()
  }

  return (
    <div
      className='!block'
      style={{
        textAlign: attrs.position,
      }}>
      <figure className='inline-block'>
        {renderImage()}
        {renderImageCaption()}
      </figure>
    </div>
  )
}

const ELEMENT_TYPES: IJsonToElementTags = {
  blockquote: (attrs, children) => {
    return <blockquote {...attrs}>{children}</blockquote>
  },
  h1: (attrs, children) => {
    return (
      <Typography
        tag={TypographyTag.h1}
        variant={TypographyVariant.BodyXLargeBold}
        className='text-primary-heading'
        {...attrs}>
        {children}
      </Typography>
    )
  },
  h2: (attrs, children) => {
    return (
      <Typography
        variant={TypographyVariant.Heading2}
        tag={TypographyTag.h2}
        {...attrs}>
        {children}
      </Typography>
    )
  },
  h3: (attrs, children) => {
    return (
      <Typography
        variant={TypographyVariant.Heading3}
        tag={TypographyTag.h3}
        {...attrs}>
        {children}
      </Typography>
    )
  },
  h4: (attrs, children) => {
    return (
      <Typography
        variant={TypographyVariant.Heading4}
        tag={TypographyTag.h4}
        {...attrs}>
        {children}
      </Typography>
    )
  },
  h5: (attrs, children) => {
    return (
      <Typography
        variant={TypographyVariant.Heading5}
        tag={TypographyTag.h5}
        className={attrs.class}
        {...attrs}>
        {children}
      </Typography>
    )
  },
  h6: (attrs, children) => {
    return (
      <Typography
        variant={TypographyVariant.Heading6}
        tag={TypographyTag.h6}
        className={attrs.class}
        {...attrs}>
        {children}
      </Typography>
    )
  },
  img: (
    attrs,
    _child,
    _jsonBlock,
    figureStyles: {
      fieldsEdited?: []
      anchorLink?: { url?: string; [key: string]: string }
      caption?: string
      alignment?: string
      position?: { [key: string]: string }
    }
  ) => {
    let caption: ReactElement
    let figureElement: ReactElement
    let img: ReactElement

    if (
      !figureStyles?.fieldsEdited ||
      figureStyles?.fieldsEdited?.length === 0
    ) {
      return <ContentImage attrs={attrs} />
    }

    if (figureStyles.anchorLink) {
      img = (
        <Link {...figureStyles.anchorLink} to={figureStyles.anchorLink.url}>
          <ContentImage attrs={attrs} />
        </Link>
      )
    } else {
      img = <ContentImage attrs={attrs} />
    }

    if (figureStyles.caption) {
      if (figureStyles.alignment === 'center') {
        caption = (
          <figcaption style={{ textAlign: 'center' }}>
            {figureStyles.caption}
          </figcaption>
        )
      } else {
        caption = <figcaption>{figureStyles.caption}</figcaption>
      }
    } else {
      caption = null
    }

    if (figureStyles.position) {
      figureElement = (
        <figure {...figureStyles.position}>
          {img}
          {caption}
        </figure>
      )
    } else if (figureStyles.caption) {
      figureElement = (
        <figure>
          {img}
          {caption}
        </figure>
      )
    } else {
      figureElement = img
    }

    return figureElement
  },
  embed: (attrs) => {
    return (
      <iframe
        title={attrs.title || 'Embedded frame'}
        className={classNames('max-w-full', attrs.class)}
        {...validateEmbedAttrs(attrs)}
      />
    )
  },
  p: (attrs, children) => {
    return (
      <Typography
        variant={TypographyVariant.BodyRegularExtraLineHeight}
        tag={TypographyTag.p}
        className={classNames('text-secondary-muted', attrs.class)}
        {...attrs}>
        {children}
      </Typography>
    )
  },
  ol: (attrs, children) => {
    return (
      // @ts-ignore
      <ol
        {...attrs}
        className={`${attrs.class} list-[revert] p-[revert] m-[revert]`}>
        {children}
      </ol>
    )
  },
  ul: (attrs, children) => {
    return (
      <ul
        {...attrs}
        className={`${attrs.class} list-[revert] p-[revert] m-[revert]`}>
        {children}
      </ul>
    )
  },
  code: (attrs, children, jsonValue) => {
    const renderContent = () => {
      const content = (jsonValue as RichTextNode).content as RichTextCustomNode
      const codeBlockContent = content?.code?.code_block_contents

      if (codeBlockContent) {
        return <span>{codeBlockContent}</span>
      }

      return children
    }

    return (
      <pre className='rounded-md py-2 px-4 text-wrap text-sm' {...attrs}>
        {renderContent()}
      </pre>
    )
  },
  li: (attrs, children) => {
    return (
      <li {...attrs}>
        <Typography
          tag={TypographyTag.div}
          variant={TypographyVariant.BodyRegular}>
          {children}
        </Typography>
      </li>
    )
  },
  a: (attrs, children) => {
    return (
      <Link {...attrs} to={attrs.url} className='underline'>
        {children}
      </Link>
    )
  },
  table: (attrs, children) => {
    return <table {...attrs}>{children}</table>
  },
  tbody: (attrs, children) => {
    return <tbody {...attrs}>{children}</tbody>
  },
  thead: (attrs, children) => {
    return <thead {...attrs}>{children}</thead>
  },
  tr: (attrs, children) => {
    return <tr {...attrs}>{children}</tr>
  },
  td: (attrs, children) => {
    return <td {...attrs}>{children}</td>
  },
  th: (attrs, children) => {
    return <th {...attrs}>{children}</th>
  },
  row: (attrs, children) => {
    return <div {...attrs}>{children}</div>
  },
  column: (attrs, children) => {
    return <div {...attrs}>{children}</div>
  },
  'grid-container': (attrs, children) => {
    return <div {...attrs}>{children}</div>
  },
  'grid-child': (attrs, children) => {
    return <div {...attrs}>{children}</div>
  },
  hr: () => {
    return (
      <hr style={{ borderTop: '1px solid var(--color-border-line-primary)' }} />
    )
  },
  span: (attrs, children) => {
    return <span {...attrs}>{children}</span>
  },
  div: (attrs, children) => {
    return <div {...attrs}>{children}</div>
  },
  reference: (
    attrs,
    children,
    _jsonBlock,
    extraAttrs: Record<string, unknown>
  ) => {
    const modifiedAttrs: Partial<Attributes> & Record<string, unknown> =
      Object.entries(attrs).reduce((acc, [key, val]) => {
        const modifiedKey = key.replace(/-([a-z])/g, (k) => {
          return k[1].toUpperCase()
        })
        return {
          ...acc,
          ...{ [modifiedKey]: val },
        }
      }, {})
    const combinedAttrs = { ...extraAttrs, ...modifiedAttrs }

    if (combinedAttrs?.displayType === 'inline') {
      return <span {...attrs}>{children}</span>
    }
    if (combinedAttrs?.displayType === 'block') {
      return <div {...attrs}>{children}</div>
    }
    if (combinedAttrs?.displayType === 'link') {
      return (
        <Link {...attrs} to={attrs.href} className='underline'>
          {children}
        </Link>
      )
    }
    if (combinedAttrs?.displayType === 'asset') {
      return <figure {...attrs}>{children}</figure>
    }

    if (combinedAttrs?.assetType?.includes('image')) {
      return <ReferencedImage attrs={combinedAttrs} />
    }

    return <span {...attrs}>{children}</span>
  },
  inlineCode: (attrs, children) => {
    return <code {...attrs}>{children}</code>
  },
  fragment: (_attrs, children) => {
    return children
  },
  // style: (attrs, children) => {
  //   return <style {...attrs}>{children}</style>
  // },
  // script: (attrs, children) => {
  //   return <script {...attrs}>{children}</script>
  // },
  doc: (attrs, children) => {
    return (
      <Typography
        variant={TypographyVariant.BodyRegularExtraLineHeight}
        tag={TypographyTag.div}
        className='text-secondary-muted'
        {...attrs}>
        {children}
      </Typography>
    )
  },
  image: (attrs, _children, jsonValue) => {
    const content = (jsonValue as RichTextNode).content as RichTextCustomNode
    const { src, altText, width, height } = content.image

    return (
      <Image
        className='mx-auto rounded-md'
        src={src}
        alt={altText}
        width={width}
        height={height}
        loading='eager'
        {...attrs}
      />
    )
  },
  video: (attrs, _children, jsonValue) => {
    const content = (jsonValue as RichTextNode).content as RichTextCustomNode

    return (
      <div
        className={classNames(
          'mx-auto flex items-center justify-center',
          styles.video
        )}>
        <VideoPlayer
          source={content.video}
          muted={false}
          autoplay={false}
          {...attrs}
        />
      </div>
    )
  },
}
const TEXT_WRAPPERS: IJsonToElementTextTags = {
  bold: (children) => {
    return <strong className='font-extrabold'>{children}</strong>
  },
  italic: (children) => {
    return <em>{children}</em>
  },
  underline: (children) => {
    return <u>{children}</u>
  },
  strikethrough: (children) => {
    return <del>{children}</del>
  },
  superscript: (children) => {
    return <sup>{children}</sup>
  },
  subscript: (children) => {
    return <sub>{children}</sub>
  },
  inlineCode: (children) => {
    return <code>{children}</code>
  },
}

export const toRedactor = (
  jsonValue: RichTextAnyNode,
  key: string | number,
  options?: IJsonToElementOptions
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ReactElement | ReactElement[] => {
  const textWrappers = {
    ...TEXT_WRAPPERS,
    ...(options?.customTextWrappers || {}),
  }

  const redactorAttributes = jsonValue?.attrs?.['redactor-attributes'] || {}
  const allAttrs = {
    ...(jsonValue?.attrs || {}),
    ...redactorAttributes,
    style: jsonValue?.attrs?.style || redactorAttributes?.style,
  }

  if (allAttrs.style) {
    // Make all style attribute keys camelCase
    allAttrs.style = Object.keys(allAttrs.style).reduce((acc, key) => {
      acc[
        key.replace(/-([a-z])/g, (g): string => {
          return g[1].toUpperCase()
        })
      ] = allAttrs.style[key]
      return acc
    }, {})
  }

  delete allAttrs['redactor-attributes']

  if (typeof jsonValue?.text === 'string') {
    const args: { className?: string; id?: string } = {}

    if (typeof jsonValue.classname === 'string') {
      args.className = jsonValue.classname
    }

    if (typeof jsonValue.id === 'string') {
      args.id = jsonValue.id
    }

    const innerText = jsonValue.text
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .split(/\n/g)
      .map((text, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && <br />}
            {text}
          </Fragment>
        )
      })

    let text =
      Object.keys(args).length > 0 ? (
        <span {...args}>{innerText}</span>
      ) : (
        innerText
      )

    Object.entries(jsonValue).forEach(([key]) => {
      if (textWrappers.hasOwnProperty(key)) {
        text = textWrappers[key](text)
      }
    })

    if (allAttrs) {
      text = <span {...allAttrs}>{text}</span>
    }

    return <Fragment key={key}>{text}</Fragment>
  }

  const elementTypes = {
    ...ELEMENT_TYPES,
    ...(options?.customElementTypes || {}),
  }

  const children = jsonValue?.children
    ? Array.from(jsonValue.children).reduce((acc, child, i) => {
        if (
          typeof child.text === 'string' &&
          child.text === '' &&
          jsonValue?.type !== 'reference'
        ) {
          return acc
        }
        return [...acc, toRedactor(child, i, options)]
      }, [])
    : null

  const childElements = children?.length ? <>{Array.from(children)}</> : null
  const isCustomContent = (jsonValue as RichTextNode).content != null
  const hasChildren = children?.length
  const canHaveEmptyChildren =
    jsonValue?.type === 'embed' || jsonValue?.type === 'hr'

  // !Do not remove the below check for children.length
  // !This has been added to ensure empty elements are not rendered.
  // !Empty embed and hr can be rendered as text values are not required here
  // !Custom rich text content have different formats and don't have "children"
  if (
    elementTypes[jsonValue?.type] &&
    (canHaveEmptyChildren || hasChildren || isCustomContent)
  ) {
    return (
      <Fragment key={key}>
        {elementTypes[jsonValue.type](
          allAttrs,
          childElements,
          jsonValue,
          jsonValue
        )}
      </Fragment>
    )
  }

  return <Fragment key={key}>{childElements}</Fragment>
}

const RichText = ({ content, options }: RichTextProps) => {
  const json = Array.isArray(content) ? content : [content]

  return (
    <div className={classNames(styles.richTextWrapper, 'rich-text-wrapper')}>
      {json.map((node, index) => {
        return toRedactor(node, index, options)
      })}
    </div>
  )
}

export default RichText
