import { forwardRef, useRef } from 'react'
import Head from 'next/head'
import Script from 'next/script'
import { Cappasity3dSpinnerProps } from './Cappasity3dSpinner.types'

const CAPPASITY_SCRIPT_DOMAIN = 'https://api.cappasity.com'
const CAPPASITY_SCRIPT_URL = `${CAPPASITY_SCRIPT_DOMAIN}/api/player/cappasity-ai`

export const Cappasity3dSpinner = forwardRef<
  HTMLDivElement,
  Cappasity3dSpinnerProps
>(({ cappasityModelId, isSeen }, ref) => {
  const containerRef = useRef<HTMLIFrameElement>(null)
  const cappasityModelDocumentUrl = `${CAPPASITY_SCRIPT_DOMAIN}/api/player/${cappasityModelId}/embedded?arbutton=0&autorun=1&closebutton=0&logo=0&analytics=1&uipadx=&uipady=&enablestoreurl=0&storeurl=&hidehints=1&language=&autorotate=1&autorotatetime=10&autorotatedelay=2&autorotatedir=1&hidefullscreen=1&hideautorotateopt=1&hidesettingsbtn=1&enableimagezoom=1&zoomquality=1&hidezoomopt=1`
  return (
    <div ref={ref} className='w-full h-full'>
      <Head>
        <link rel='preconnect' href={CAPPASITY_SCRIPT_DOMAIN} />
        <link rel='prefetch' href={CAPPASITY_SCRIPT_URL} as='script' />
      </Head>
      {isSeen && (
        <Script src={CAPPASITY_SCRIPT_URL} strategy='lazyOnload' defer />
      )}
      {isSeen && (
        <iframe
          ref={containerRef}
          width='100%'
          height='100%'
          allowFullScreen
          className='border-0'
          title={cappasityModelId}
          src={cappasityModelDocumentUrl}
        />
      )}
    </div>
  )
})
