import { forwardRef, useState } from 'react'
import { VisuallyHidden } from '@overdose/components'
import { IconArrowLeft, IconArrowRight, Icon360View } from '@tabler/icons-react'
import classNames from 'classnames'
import type { Swiper as SwiperType } from 'swiper'
import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Button } from '~/components/Button'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import 'swiper/css'
import styles from './ProductGallery.module.css'
import { ProductGalleryProps } from './ProductGallery.types'

const ProductGallery = forwardRef<HTMLDivElement, ProductGalleryProps>(
  ({ slides = [], show360Icon }, ref) => {
    const [swiper, setSwiper] = useState<SwiperType | null>(null)
    const [page, setPage] = useState<number>(0)

    const handleSlidePrev = () => {
      if (swiper) {
        swiper.slidePrev()
      }
    }

    const handleSlideNext = () => {
      if (swiper) {
        swiper.slideNext()
      }
    }

    const handleSlideTo = (pageIndex: number) => {
      if (swiper) {
        swiper.slideTo(pageIndex)
      }
    }

    const handleChangeSlide = () => {
      if (swiper) {
        setPage(swiper.realIndex)
      }
    }

    const renderSlides = slides?.length > 0 ? slides : []

    return (
      <div className={styles.root} ref={ref}>
        <Swiper
          className='h-full'
          modules={[Controller]}
          onSwiper={(instance) => {
            return setSwiper(instance)
          }}
          loop={renderSlides?.length > 1}
          onSlideChange={handleChangeSlide}>
          {renderSlides.map((slide, index) => {
            return (
              <SwiperSlide key={index}>
                <div className={classNames(styles.slide, 'flex')}>{slide}</div>
              </SwiperSlide>
            )
          })}
        </Swiper>
        {slides?.length > 1 && (
          <div
            className={classNames(
              'hidden xl:flex items-center gap-x-2',
              styles.pagination,
              {
                '!flex': slides?.length >= Number('9'),
              }
            )}>
            <Button
              iconOnly
              variant='ghost'
              style={{
                '--btn-icon-size': '20px',
              }}
              size='xs'
              aria-label='previous'
              onClick={handleSlidePrev}
              icon={<IconArrowLeft size={20} />}>
              <VisuallyHidden>Previous</VisuallyHidden>
            </Button>

            <Typography
              tag={TypographyTag.div}
              variant={TypographyVariant.BodyRegularBold}
              className='text-primary-heading'>
              {`${page + 1}/${slides.length}`}
            </Typography>

            <Button
              iconOnly
              variant='ghost'
              style={{
                '--btn-icon-size': '20px',
              }}
              size='xs'
              aria-label='next'
              onClick={handleSlideNext}
              icon={<IconArrowRight size={20} />}>
              <VisuallyHidden>Next</VisuallyHidden>
            </Button>
          </div>
        )}
        {slides?.length > 1 && slides?.length < Number('9') && (
          <div
            className={classNames(
              'flex xl:!hidden items-center bottom-4 md:bottom-9',
              styles.m_pagination
            )}>
            {renderSlides.map((_, index) => {
              return (
                <button
                  key={index}
                  type='button'
                  aria-label='dot'
                  className={classNames(
                    styles.page_dot,
                    page === index ? styles.page_dot_active : ''
                  )}
                  onClick={() => {
                    return handleSlideTo(index)
                  }}
                />
              )
            })}
          </div>
        )}
        {show360Icon && (
          <div className='absolute right-4 bottom-4 md:right-0 md:bottom-8 z-10'>
            <Button
              theme={{ root: styles.rotateBtn }}
              icon={<Icon360View />}
              iconOnly
              shape='circle'
              status='success'
              onClick={() => {
                handleSlideTo(slides.length - 1)
              }}>
              <VisuallyHidden>show 360 view</VisuallyHidden>
            </Button>
          </div>
        )}
      </div>
    )
  }
)

export default ProductGallery
