import React from 'react'
import classNames from 'classnames'
import { Image } from '~/components/Image'
import { getImageSrc } from '~/helpers'
import { Button } from '../Button'
import RichText from '../RichText/RichText'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './DisruptorPromoCard.module.css'
import { DisruptorPromoCardProps } from './DisruptorPromoCard.types'

const DisruptorPromoCard = ({
  thumbnail,
  icon,
  description,
  action,
}: DisruptorPromoCardProps) => {
  return (
    <div className={styles.wrapper}>
      {thumbnail?.src && (
        <Image
          src={getImageSrc(thumbnail.src, '720')}
          sources={
            <source
              srcSet={getImageSrc(thumbnail.src, '600')}
              media='(max-width: 600px)'
            />
          }
          addSrcSet={false}
          alt={thumbnail?.altText}
          width={thumbnail?.width}
          height={thumbnail?.height}
          className='w-full h-full'
        />
      )}
      <div className='absolute w-full h-full p-6 lg:p-10 flex flex-col items-baseline'>
        {icon?.src && (
          <div className={styles.icon}>
            <Image
              src={getImageSrc(icon.src, '720')}
              sources={
                <source
                  srcSet={getImageSrc(icon.src, '600')}
                  media='(max-width: 600px)'
                />
              }
              alt={icon?.altText}
              width={icon.width}
              height={icon.height}
              className='w-full h-full'
              style={{ objectPosition: 'left', objectFit: 'contain' }}
            />
          </div>
        )}
        {description && (
          <div
            className={classNames(
              'mt-6 w-1/2 lg:w-2/5 xl:w-1/4',
              styles.description
            )}>
            <RichText content={description} />
          </div>
        )}
        {action?.href && (
          <Button
            status='success'
            href={action.href}
            theme={{ root: styles.action }}>
            <Typography
              tag={TypographyTag.span}
              variant={TypographyVariant.BodyLargeBold}>
              {action?.title}
            </Typography>
          </Button>
        )}
      </div>
    </div>
  )
}

export default DisruptorPromoCard
