import classNames from 'classnames'

import { CardStackProps } from './CardStack.types'

const CardStack = ({ className, children }: CardStackProps) => {
  return (
    <div className={classNames(['card-stack flex flex-col', className])}>
      {children}
    </div>
  )
}

export default CardStack
