import { VisuallyHidden } from '@overdose/components'
import classNames from 'classnames'
import { Button } from 'components/Button'
import { useRouter } from 'next/router'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { Link } from '../Link'
import styles from './Pagination.module.scss'
import { PaginationProps } from './Pagination.types'

const Pagination = ({
  customWidth,
  showingItemCount,
  totalItemsCount,
  showProgress,
  isLoading = false,
  totalPages,
  action,
  onClick,
}: PaginationProps) => {
  const percentage = 100
  const router = useRouter()
  const pathName = router?.asPath?.split('?')?.[0]
  const getPageLink = (num) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { slug, ...params } = router.query
    const query = { ...params, p: num }
    if (num === 1) {
      delete query.p
    }
    return {
      pathname: pathName,
      query,
    }
  }

  return (
    <div
      className={classNames(
        styles.wrapper,
        `text-center w-[163px] ${customWidth} 2xl:w-full`
      )}>
      {showProgress && !!showingItemCount && !!totalItemsCount ? (
        <>
          <div className='text-primary-heading mb-4'>
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodyRegular}>
              Showing {showingItemCount} of {totalItemsCount}
            </Typography>
          </div>
          <div className={classNames(styles.progress, 'mb-8 -mt-0.5')}>
            <div
              className={styles.tumb}
              style={{
                width: `${
                  totalItemsCount < 1
                    ? 0
                    : (percentage * showingItemCount) / totalItemsCount
                }%`,
              }}
            />
          </div>
        </>
      ) : null}

      {action && (
        <Button
          status='secondary'
          theme={{ root: 'w-full h-[44px] normal-case !rounded' }}
          ariaLabel={action.title}
          loading={isLoading}
          onClick={onClick}>
          <Typography
            tag={TypographyTag.span}
            variant={TypographyVariant.BodyRegularBold}>
            {action.title}
          </Typography>
        </Button>
      )}

      {Array.from({ length: totalPages }, (_, index) => {
        return index + 1
      }).map((num) => {
        return (
          <VisuallyHidden key={num}>
            <Link
              key={num}
              rel={num === 1 ? 'canonical' : ''}
              href={getPageLink(num)}>{`Page ${num}`}</Link>
          </VisuallyHidden>
        )
      })}
    </div>
  )
}

export default Pagination
