import React from 'react'
import { Tag as BaseTag } from '@overdose/components'

import classNames from 'classnames'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './Tag.module.scss'
import { TagProps } from './Tag.types'

function Tag({ children, variant, theme, backgroundColor, color }: TagProps) {
  const variantMap = {
    default: 'default',
    primary: 'success',
    secondary: 'pending',
  }

  return (
    <BaseTag
      theme={theme}
      color={variantMap[variant]}
      style={{ backgroundColor, color }}>
      <Typography
        tag={TypographyTag.span}
        variant={TypographyVariant.BodySmallBold}
        className={classNames(styles.tag, 'block')}>
        {children}
      </Typography>
    </BaseTag>
  )
}

export default Tag
