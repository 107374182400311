import { VisuallyHidden } from '@overdose/components'
import classNames from 'classnames'
import { SectionProps } from 'shared-types'
import { Link } from '~/components'
import { Image } from '~/components/Image'
import { SectionContainer } from '~/components/SectionContainer'
import jaycarLogo from '~/public/images/jaycar-logo.svg'
import rtmLogo from '~/public/images/rtm-logo.svg'
import styles from './SimpleHeader.module.css'

const SimpleHeader = ({ width = 'full' }: SectionProps) => {
  return (
    <SectionContainer
      width={width}
      tag='header'
      innerClassName='relative px-4 py-6 lg:py-5 lg:px-20 h-20 bg-[var(--header-color-bg)]'
      wrapperClassName='sticky top-0 z-10'>
      <Link title='View Home Page' href='/' className='inline-block'>
        <Image
          src={jaycarLogo}
          alt='Jaycar Logo'
          width={148}
          height={40}
          className={classNames('hidden', styles.jaycarLogo)}
        />
        <Image
          src={rtmLogo}
          alt='RTM Logo'
          width={148}
          height={40}
          className={classNames('hidden', styles.rtmLogo)}
        />
        <VisuallyHidden>View Home Page</VisuallyHidden>
      </Link>
    </SectionContainer>
  )
}

export { SimpleHeader }
