import { useEffect, useState } from 'react'
import { VisuallyHidden } from '@overdose/components'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'

export const PageProgressBar = () => {
  const [isChangingRoute, setIsChangingRoute] = useState(false)
  const progressTime = 200

  const router = useRouter()

  useEffect(() => {
    NProgress.configure({
      minimum: 0.33,
      trickleSpeed: 100,
      parent: 'body',
      showSpinner: false,
    })
  })

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsChangingRoute(true)
    }

    const handleRouteChangeEnd = () => {
      setIsChangingRoute(false)
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeError', handleRouteChangeEnd)
    router.events.on('routeChangeComplete', handleRouteChangeEnd)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.on('routeChangeError', handleRouteChangeEnd)
      router.events.on('routeChangeComplete', handleRouteChangeEnd)
    }
  }, [router.events])

  useEffect(() => {
    let returnFn = () => {
      // A comment to satisfy SonarCloud - empty-arrow-function
    }
    if (typeof document !== 'undefined' && isChangingRoute) {
      const timeout = setTimeout(() => {
        NProgress.start()
        returnFn = () => {
          return NProgress.done()
        }
      }, progressTime)

      returnFn = () => {
        return clearTimeout(timeout)
      }
    }
    return () => {
      return returnFn()
    }
  }, [isChangingRoute])

  return <VisuallyHidden>Progress Bar</VisuallyHidden>
}
