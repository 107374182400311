import { Fragment } from 'react'
import { VisuallyHidden } from '@overdose/components'
import classNames from 'classnames'
import { ProductVariantOptionGroupSimpleProps } from 'shared-types/src/variants'
import { Button } from '../Button'
import Card from '../Card/Card'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './ProductVariantOptionGroupSimple.module.scss'

export const ProductVariantOptionGroupSimple = ({
  values,
  title,
  type,
}: ProductVariantOptionGroupSimpleProps) => {
  return (
    <Card
      title={
        <Typography
          variant={TypographyVariant.BodyRegularBold}
          tag={TypographyTag.span}>
          {title}
        </Typography>
      }
      variant='default'>
      {!!values?.length && (
        <div className={classNames('flex gap-4', styles.valuesWrapper)}>
          {values.map(({ color, selected, title, url }) => {
            const isColorVariant = color?.hex
            return (
              <Fragment key={url}>
                <Button
                  href={url}
                  theme={{
                    root: classNames('h-9 rounded-full', styles.button, {
                      [styles.selectedTextBorder]: selected,
                      'w-9 p-0': isColorVariant,
                      [styles.colorBorder]: isColorVariant && !selected,
                      [styles.selectedColorBorder]: isColorVariant && selected,
                    }),
                  }}
                  style={{ backgroundColor: color?.hex }}>
                  {type === 'text' && title}
                  <VisuallyHidden>Link to {title}</VisuallyHidden>
                </Button>
              </Fragment>
            )
          })}
        </div>
      )}
    </Card>
  )
}
