import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { debounce } from 'lodash-es'
import dynamic from 'next/dynamic'
import NextError from 'next/error'
import { useRouter } from 'next/router'
import BazaarVoiceScript from '~/components/BazaarVoiceScript/BazaarVoiceScript'
import { MockPageProps, PageProps } from '~/components/Page/Page.types'
import { PageProgressBar } from '~/components/PageProgressBar'
import SkipLink from '~/components/SkipLink/SkipLink'
import {
  useCart,
  useCustomer,
  useUserDeliveryPostcode,
  useUserPreferredStore,
} from '~/hooks'
import { GTM, GTMEvent, handleLoadEvent } from '~/lib'
import { footerSectionNames, headerSectionNames } from '~/sections'
import { ComposeSections } from '../ComposeSections'
import Seo from '../Seo/Seo'

const AuthenticationDrawer = dynamic(
  async () => {
    const mod = await import('../AuthenticationDrawer')
    return mod.default
  },
  { ssr: false }
)

let loadEvent = ''

export const Page = ({ page, bazaarVoice }: PageProps | MockPageProps) => {
  const { postcode } = useUserDeliveryPostcode() || {}
  const { store } = useUserPreferredStore() || {}
  const { customer } = useCustomer()
  const { cart } = useCart()
  const dispatchCartContentPushWasCalled = useRef(false)
  const gtagId = page?.gtagId || ''
  const pageProps = useMemo(() => {
    return { page }
  }, [page])
  const router = useRouter()

  const pageLoadEvent = useCallback(() => {
    GTM.dispatch(GTMEvent.PAGE_LOAD, {
      pageType: page?.head?.pageType,
      isSignedIn: customer?.isSignedIn,
      preferred_store: store?.displayName,
      delivery_postcode: postcode || '',
      loyalty_points: customer?.rewardPoints || '',
      customer_group: customer?.customerGroup?.key || '',
    })
  }, [
    customer?.customerGroup?.key,
    customer?.isSignedIn,
    customer?.rewardPoints,
    page?.head?.pageType,
    postcode,
    store?.displayName,
  ])

  useEffect(() => {
    // Add a scrollable div to the DOM
    const scrollDiv = document.createElement('div')
    scrollDiv.style.overflow = 'scroll'
    document.body.appendChild(scrollDiv)

    // Get the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth

    // Cleanup the div
    document.body?.removeChild(scrollDiv)

    //  Set the width to a CSS variable
    document.body.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`)
  }, [])

  useEffect(() => {
    GTM.dispatch(GTMEvent.SERVER_SIDE_TRACKING)
    pageLoadEvent()
  }, [pageLoadEvent])

  useEffect(() => {
    if (dispatchCartContentPushWasCalled.current || !cart) {
      return
    }
    dispatchCartContentPushWasCalled.current = true
    GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
    GTM.dispatch(GTMEvent.CART_CONTENTS_PUSH, cart)
  }, [cart])

  useLayoutEffect(() => {
    function updateVhSize() {
      const vh = window.innerHeight * Number('0.01')
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    window.addEventListener('resize', debounce(updateVhSize))
    updateVhSize()
    return () => {
      return window.removeEventListener('resize', updateVhSize)
    }
  }, [])

  useEffect(() => {
    if (gtagId && loadEvent !== router.asPath) {
      handleLoadEvent(pageProps, gtagId, router)
      loadEvent = router.asPath
    }
  }, [gtagId, pageProps, router.asPath, router])

  if (!page?.sections?.length) {
    return <NextError statusCode={500} />
  }

  const { headerSections, mainSections, footerSections } = page.sections.reduce(
    (acc, section) => {
      if (headerSectionNames.includes(section.__typename)) {
        acc.headerSections.push(section)
        return acc
      }
      if (footerSectionNames.includes(section.__typename)) {
        acc.footerSections.push(section)
        return acc
      }
      acc.mainSections.push(section)
      return acc
    },
    {
      headerSections: [],
      mainSections: [],
      footerSections: [],
    }
  )

  return (
    <>
      <Seo page={page as PageProps['page']} />
      <PageProgressBar />
      <SkipLink title='Skip to main content' to='#main-content' />
      <ComposeSections sections={headerSections} page={page} />
      <main id='main-content'>
        <ComposeSections sections={mainSections} page={page} />
      </main>
      <ComposeSections sections={footerSections} page={page} />
      <AuthenticationDrawer />
      {bazaarVoice && <BazaarVoiceScript {...bazaarVoice} />}
    </>
  )
}
