import React, { Fragment } from 'react'
import { jsonLdScriptProps } from 'react-schemaorg'
import { VisuallyHidden } from '@overdose/components'
import dayjs from 'dayjs'
import Head from 'next/head'
import Link from 'next/link'
import { BreadcrumbList } from 'schema-dts'
import { Page, SiteNavigationItem } from 'shared-types'
import { useFormatPrice } from '~/hooks'
import { ProjectDetailsProps } from '~/sections/ProjectDetails'
import { ProjectStepsProps } from '~/sections/ProjectSteps'
import { Project, SeoProps } from './Seo.types'

const SubNavItem = ({
  subNavItemLink,
}: {
  subNavItemLink: SiteNavigationItem
}) => {
  return (
    <>
      {subNavItemLink?.url && (
        <Link
          href={subNavItemLink?.url}
          title={subNavItemLink?.title}
          itemProp='url'
          tabIndex={-1}>
          <span itemProp='name'>{subNavItemLink?.title}</span>
        </Link>
      )}

      {subNavItemLink?.children?.map((child, index) => {
        return <SubNavItem key={index} subNavItemLink={child} />
      })}
    </>
  )
}

/*
  The meta robots tag is hardcoded to prevent search engines from indexing the page before go-live.
  TODO: Revert hardcoded robots meta tag value and shift from code to env
  NOTE: Manually kept in sync with apps/web/pages/api/robots.ts::RESTRICTED_SUBDOMAINS
 */
const HARDCODED_ROBOTS_META_TAG_VALUE = 'NOINDEX,NOFOLLOW'
const RESTRICTED_SUBDOMAINS = ['devct.jaycar', 'uatct.jaycar', 'shop.jaycar']

const getRobotsMetaTagValue = (noFollow, noIndex, rootUrl?: string) => {
  const isRestrictedSubDomain = !!RESTRICTED_SUBDOMAINS.filter((domain) => {
    return rootUrl?.includes(domain)
  }).length

  if (!isRestrictedSubDomain) {
    let robotsMetaTagValue = ''
    if (noIndex && noFollow) {
      robotsMetaTagValue = 'NOINDEX,NOFOLLOW'
    } else if (noIndex) {
      robotsMetaTagValue = 'NOINDEX,FOLLOW'
    } else if (noFollow) {
      robotsMetaTagValue = 'INDEX,NOFOLLOW'
    } else {
      robotsMetaTagValue = 'INDEX,FOLLOW'
    }
    return robotsMetaTagValue
  }

  return HARDCODED_ROBOTS_META_TAG_VALUE
}

const generateMetaTagsAndTitle = (title: string, head: Page['head']) => {
  const { canonical, description, og, locale, countryName } = head

  return (
    <>
      {title && <title>{title}</title>}
      {description && <meta name='description' content={description} />}
      {canonical && <link rel='canonical' href={canonical} />}
      {canonical && <meta property='og:url' content={canonical} />}
      {og?.image && <meta property='og:image' content={og?.image} />}
      <meta property='og:type' content='website' />
      {og?.siteName && <meta property='og:site_name' content={og?.siteName} />}
      {locale && <meta property='og:locale' content={locale} />}
      {og?.title && <meta property='og:title' content={og?.title} />}
      {description && <meta property='og:description' content={description} />}
      {countryName && <meta property='og:country-name' content={countryName} />}
    </>
  )
}

const renderSearchPageScript = (rootUrl: string, searchPageUrl: string) => {
  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: `{
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "${rootUrl}",
              "potentialAction": {
                "@type": "SearchAction",
                "target": {
                  "@type": "EntryPoint",
                  "urlTemplate":  "${searchPageUrl}?q={search_term_string}"
                },
                "query-input": "required name=search_term_string"
              }
            }`,
      }}
    />
  )
}

const renderCategoryScript = (category: Page['head']['category']) => {
  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: `{
                "@context": "https://schema.org",
                "@type": "CollectionPage",
                "@id": "${category?.url || ''}",
                "headline": "${category?.name || ''}",
                "url": "${category?.url || ''}",
                "name": "${category?.name || ''}"
              }`,
      }}
    />
  )
}

const renderAddress = (address: Page['head']['store']['address']) => {
  if (!address) {
    return null
  }
  return `{
    "@type": "PostalAddress",
    "streetAddress": "${address.streetName || ''}",
    "addressLocality": "${address.city || ''}",
    "addressRegion": "${address.stateCode || ''}",
    "postalCode": "${address.postalCode || ''}",
    "addressCountry": "${address.countryCode || ''}"
  }`
}

const renderGeo = (geo: Page['head']['store']['geo']) => {
  if (!geo) {
    return null
  }

  return `{
    "@type": "GeoCoordinates",
    "latitude": "${geo.latitude || ''}",
    "longitude": "${geo.longitude || ''}"
  }`
}

const renderStoreScript = (store: Page['head']['store']) => {
  if (!store) {
    return null
  }
  const {
    name,
    imageURL,
    url,
    phone,
    email,
    hasMap,
    address,
    geo,
    openingHours,
  } = store
  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: `{
              "@context": "https://schema.org",
              "@type": "ElectronicsStore",
              "name": "${name || ''}",
              "image": "${imageURL || ''}",
              "@id": "${url || ''}",
              "url": "${url || ''}",
              "telephone": "${phone || ''}",
              "email": "${email || ''}",
              "hasMap": "${hasMap || ''}",
              "address": ${renderAddress(address)},
              "geo": ${renderGeo(geo)},
              "openingHoursSpecification": [${openingHours?.map(
                (openingHour) => {
                  return `{
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": "${openingHour?.daysOfWeek || ''}",
                        "opens": "${openingHour?.openingTime || ''}",
                        "closes": "${openingHour?.closingTime || ''}"
                      }`
                }
              )}]
            }`,
      }}
    />
  )
}

const renderOffer = (offer: Page['head']['product']['offer'] | null) => {
  if (!offer) {
    return null
  }
  return `{
    "@type": "Offer",
    "url": "${offer.url || ''}",
    "priceCurrency": "${offer.priceCurrency || ''}",
    "price": "${offer.price || ''}",
    "availability": "https://schema.org/${
      offer.inStock ? 'InStock' : 'OutOfStock'
    }",
    "itemCondition": "https://schema.org/NewCondition",
		"priceValidUntil": "${
      offer.priceValidUntil
        ? dayjs(offer.priceValidUntil).format('YYYY-MM-DD')
        : ''
    }",
		"hasMerchantReturnPolicy": { 
			"@type": "MerchantReturnPolicy", 
			"applicableCountry": "${
        offer?.hasMerchantReturnPolicy?.applicableCountry || ''
      }", 
			"returnPolicyCategory": "${
        offer?.hasMerchantReturnPolicy?.returnPolicyCategory || ''
      }",
			"merchantReturnDays": "${
        offer?.hasMerchantReturnPolicy?.merchantReturnDays || ''
      }", 
			"returnMethod": "${offer?.hasMerchantReturnPolicy?.returnMethod || ''}", 
			"returnFees": "${offer?.hasMerchantReturnPolicy?.returnFees || ''}" 
		}
  }`
}

const renderAggregateRating = (
  aggregateRating: Page['head']['product']['aggregateRating']
) => {
  if (!aggregateRating) {
    return null
  }
  return `{
    "@type": "AggregateRating",
    "ratingValue": "${aggregateRating.ratingValue || '0'}",
    "bestRating": "${aggregateRating.bestRating || '5'}",
    "worstRating": "${aggregateRating.worstRating || '1'}",
    "ratingCount": "${aggregateRating.ratingCount || '0'}"
  }`
}

// TODO: fix the product specification later
// const renderSize = (specs: Page['head']['product']['specs']) => {
//   if (!specs) {
//     return null
//   }

//   const sizeData = specs
//     .map((spec) => {
//       return `
// 			"${spec.name.toLowerCase()}" : {
// 				"@type": "QuantitativeValue",
// 			  "value": "${spec.value || ''}",
// 				"unitCode": "${spec.unitCode.toUpperCase() || ''}"
// 			}`
//     })
//     .join(',')

//   return `{
//     "@type": "SizeSpecification", ${sizeData}
//   }`
// }

const renderImages = (images: Page['head']['product']['imageUrls']) => {
  if (!images) {
    return null
  }

  return images
    .map((image) => {
      return `"${image.url}"`
    })
    .join(',')
}

const renderProductScript = (
  product: Page['head']['product'],
  canonical?: string
) => {
  if (!product) {
    return null
  }

  const {
    name,
    imageUrls,
    description,
    brandName,
    sku,
    model,
    colour,
    mpn,
    offer,
    // aggregateRating,
    // specs,
    // documentation,
  } = product

  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: `{
          "@context": "https://schema.org/", 
          "@type": "Product", 
					"@id": "${canonical}",
          "name": "${name || ''}",
          "image": [${renderImages(imageUrls)}],
          "description": "${description.replace(/\r?\n|\r/g, '') || ''}",
          "brand": {
            "@type": "Brand",
            "name": "${brandName || ''}"
          },
          "sku": "${sku || ''}",
          "model": "${model || ''}",
          "color": "${colour || ''}",
          "mpn": "${mpn || ''}",
          "offers": ${renderOffer(offer)}
        }`,
      }}
    />
  )
}

const renderHasPart = (hasPart: Page['head']['projectDetails']['hasPart']) => {
  return hasPart.map((part) => {
    return `{
			"@type": "CreativeWork", 
			"name": "${part.name || ''}",
			"url": "${part.url || ''}"
		}`
  })
}

const renderTool = (tools: ProjectDetailsProps['materials']) => {
  return tools.map((tool) => {
    return `{
			"@type": "HowToTool", 
			"name": "${tool.product.title || ''}",
			"identifier": "${tool.product.sku || ''}"
		}`
  })
}

const renderProjectScript = (project: Project) => {
  if (!project) {
    return null
  }

  const { genre, hasPart, name, tools } = project

  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: `{
          "@context": "https://schema.org/", 
          "@type": "HowTo", 
          "name": "${name || ''}",
          "genre": "${genre || ''}",
          "hasPart": [${renderHasPart(hasPart) || []}],
          "tool": [${renderTool(tools) || []}]
        }`,
      }}
    />
  )
}

const renderOrganizationSchema = (
  organization: Page['head']['organization'],
  description: string,
  rootUrl: string
) => {
  const subjectOf = (organization.subjectOf || [])
    .map((item) => {
      return `"${item}"`
    })
    .join(', ')
  const sameAs = (organization.sameAs || [])
    .map((item) => {
      return `"${item}"`
    })
    .join(', ')

  return `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "${organization?.name || ''}",
    "alternateName": "${organization?.alternateName || ''}",
    "description": "${description || ''}",
    "url": "${rootUrl || ''}",
    "logo": "${organization?.logo || ''}",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "${organization?.contactPoint?.phone || ''}",
      "contactType": "${organization?.contactPoint?.contactType || ''}",
      "contactOption": "${organization?.contactPoint?.contactOption || ''}",
      "areaServed": "${organization?.contactPoint?.areaServed || ''}",
      "availableLanguage": "${
        organization?.contactPoint?.availableLanguage || ''
      }"
    },
    "subjectOf": [${subjectOf}],
    "sameAs": [${sameAs}]
  }`
}

const Seo = ({ page }: SeoProps) => {
  const { head, title, primaryNavigation, sections } = page
  const { formatPrice } = useFormatPrice()
  if (!head) {
    return null
  }

  if (typeof window !== 'undefined') {
    window.product = head.product
    window.category = head.category
  }

  const {
    description,
    breadcrumbs,
    alternate,
    searchPageUrl,
    rootUrl,
    category,
    store,
    product,
    organization,
    projectDetails,
    canonical,
    noFollow,
    noIndex,
  } = head

  let offer = null

  if (product?.offer) {
    offer = {
      ...product.offer,
      price: formatPrice({
        centAmount: Number(product.offer.price),
        currencyCode: product.offer.priceCurrency,
        fractionDigits: 2,
        type: 'centPrecision',
      }).replace(/[^0-9.]/g, ''),
    }
  }

  const formattedProduct = product ? { ...product, offer } : null

  const { steps, tools } = sections.reduce(
    (acc, section) => {
      if (section.__typename === 'ProjectDetails') {
        acc.tools = (section as ProjectDetailsProps)?.materials
        return acc
      }

      if (section.__typename === 'ProjectSteps') {
        acc.steps = (section as ProjectStepsProps)?.steps
        return acc
      }

      return acc
    },
    {
      tools: [],
      steps: [],
    }
  )

  const project: Project = {
    ...projectDetails,
    tools,
    steps,
  }

  const robotsMetaTagValue = getRobotsMetaTagValue(noFollow, noIndex, rootUrl)

  return (
    <>
      <Head>
        <meta name='robots' content={robotsMetaTagValue} />
        {generateMetaTagsAndTitle(title, head)}
        {!!alternate?.length &&
          alternate.map((alternateLinkInfo) => {
            return (
              <link
                key={alternateLinkInfo.hrefLang}
                rel='alternate'
                href={alternateLinkInfo.href}
                hrefLang={alternateLinkInfo.hrefLang}
              />
            )
          })}

        {breadcrumbs && breadcrumbs.length > 0 && (
          <script
            {...jsonLdScriptProps<BreadcrumbList>({
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: breadcrumbs.map((breadcrumb, index) => {
                return {
                  '@type': 'ListItem',
                  position: index + 1,
                  name: breadcrumb.title,
                  item: breadcrumb.href,
                }
              }),
            })}
          />
        )}

        {searchPageUrl && renderSearchPageScript(rootUrl, searchPageUrl)}

        {category && renderCategoryScript(category)}

        {store && renderStoreScript(store)}

        {product && renderProductScript(formattedProduct, canonical)}

        {projectDetails && renderProjectScript(project)}

        {organization && (
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{
              __html: renderOrganizationSchema(
                organization,
                description,
                rootUrl
              ),
            }}
          />
        )}
      </Head>

      {!!primaryNavigation?.length && (
        <VisuallyHidden aria-hidden theme={{ root: 'h-0' }}>
          <nav
            itemScope
            itemType='https://www.schema.org/SiteNavigationElement'>
            {primaryNavigation.map((primaryNavigationInfo, index) => {
              return (
                <Fragment key={index}>
                  {primaryNavigationInfo?.url && (
                    <Link
                      href={primaryNavigationInfo?.url}
                      title={primaryNavigationInfo?.title}
                      itemProp='url'
                      tabIndex={-1}>
                      <span itemProp='name'>
                        {primaryNavigationInfo?.title}
                      </span>
                    </Link>
                  )}

                  {primaryNavigationInfo?.children?.map((child, index) => {
                    return <SubNavItem key={index} subNavItemLink={child} />
                  })}
                </Fragment>
              )
            })}
          </nav>
        </VisuallyHidden>
      )}
    </>
  )
}

export default Seo
