import React from 'react'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import styles from './MakerHubProjectYouTubePlayer.module.css'
import { MakerHubProjectYouTubePlayerProps } from './MakerHubProjectYouTubePlayer.types'

const ReactPlayer = dynamic(
  async () => {
    const mod = await import('react-player')
    return mod.default
  },
  { ssr: false }
)

const MakerHubProjectYouTubePlayer = ({
  youtubeUrl,
  className,
  muted = false,
  autoplay = true,
}: MakerHubProjectYouTubePlayerProps) => {
  return (
    <div
      className={classNames(
        'w-full aspect-video mb-2',
        styles.root,
        className
      )}>
      <ReactPlayer
        playing={autoplay}
        controls
        volume={muted ? 0 : 1}
        url={youtubeUrl}
        width='100%'
        height='100%'
      />
    </div>
  )
}

export default MakerHubProjectYouTubePlayer
