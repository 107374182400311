import React from 'react'
import { Tag, Typography } from '@overdose/components'
import classNames from 'classnames'

import styles from './Pill.module.css'
import { PillProps } from './Pill.types'

export const Pill = ({
  title,
  icon,
  iconAlignments,
  className,
  onCheck,
}: PillProps) => {
  return (
    <Tag
      size='lg'
      checkable
      onCheck={onCheck}
      icon={icon}
      theme={{
        tag: classNames(
          {
            [styles.alignRight]: iconAlignments === 'right',
            [styles.alignLeft]: iconAlignments !== 'right',
          },
          className
        ),
      }}>
      <Typography tag='span' variant='caption' weight='bold'>
        {title}
      </Typography>
    </Tag>
  )
}
