import React from 'react'
import classNames from 'classnames'

import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './Status.module.css'
import { StatusProps } from './Status.types'

const Status = ({ status = 'success', label, className }: StatusProps) => {
  return (
    <Typography
      tag={TypographyTag.div}
      variant={TypographyVariant.BodyRegularExtraLineHeight}
      className={classNames([
        styles.wrapper,
        styles[status],
        'text-secondary-muted',
        className,
      ])}>
      {label}
    </Typography>
  )
}

export default Status
