import {
  Breadcrumbs as BaseBreadcrumbs,
  BreadcrumbItem,
  Link,
} from '@overdose/components'
import { IconHome } from '@tabler/icons-react'
import classNames from 'classnames'
import { uniqueId } from 'lodash-es'
import { SectionContainer } from '~/components/SectionContainer'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import styles from './Breadcrumbs.module.css'
import { BreadcrumbsProps } from './Breadcrumbs.types'

export const Breadcrumbs = ({ routes, width = 'narrow' }: BreadcrumbsProps) => {
  const mobileShowCount = 2
  const renderWithWrapClass = (wrapClass: string, showCount: number) => {
    return (
      <div className={wrapClass}>
        <BaseBreadcrumbs maxCount={showCount}>
          <BreadcrumbItem>
            <Link to='/' aria-label='Home' className={styles.link} title='Home'>
              <IconHome size={16} />
            </Link>
          </BreadcrumbItem>
          {!!routes.length &&
            routes.map((action, i) => {
              return (
                <BreadcrumbItem
                  key={uniqueId('bitem')}
                  style={{ overflow: 'hidden' }}>
                  <Typography
                    tag={TypographyTag.span}
                    className='overflow-hidden'
                    variant={TypographyVariant.BodySmall}>
                    {action.href ? (
                      <Link
                        to={action.href}
                        aria-label={action.title}
                        className={classNames(styles.title, {
                          '!text-primary-heading': i === routes.length - 1,
                        })}
                        title={action.title}>
                        {action.title}
                      </Link>
                    ) : (
                      <p className='truncate'>{action.title}</p>
                    )}
                  </Typography>
                </BreadcrumbItem>
              )
            })}
        </BaseBreadcrumbs>
      </div>
    )
  }

  return (
    <SectionContainer width={width} wrapperClassName='gm-breadcrumbs'>
      {renderWithWrapClass(
        'desktop-section hidden md:block md:pt-[18px] lg:pt-6 leading-4',
        routes.length + 1
      )}
      {renderWithWrapClass(
        'mobile-section md:hidden pt-4 pb-4 h-4 leading-4',
        mobileShowCount
      )}
    </SectionContainer>
  )
}
