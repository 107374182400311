import React, { Component } from 'react'
import styles from './ErrorBoundary.module.css'
import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types'

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error) {
    console.error(
      `Uncaught error in the ${this.props.section.__typename} section, compare expected and supplied section props and make sure they match`,
      {
        suppliedProps: this.props.section,
        expectedProps: `refer "${this.props.section.__typename}Props"`,
        error,
      }
    )
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div
          className={
            styles.sectionName
          }>{`There was an error in "${this.props.section.__typename}" section. Check console log for details.`}</div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
